import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import { ModalBase } from "./ModalBase";
import { SearchInput } from "../form/SearchInput";
import { useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogTitle, Grid, Table, TextField, Tooltip, Typography } from "@mui/material";
import { useModal } from "../../hooks/useModal";
import WorkspacesTwoToneIcon from '@mui/icons-material/WorkspacesTwoTone';
import { useAuthPage } from "../../hooks/useAuthPage";

export const ModalProducts = (props) => {
  const {
    entityArray,
    form,
    productos,
    onSubmit,
    isCadena,
    emisores,
    openProductsModal,
    productosEmisores = []
  } = props;
  const { isOpenProducts, closeModalProducts, setOpenToast, isOpenConfirmProductsDialog, closeProductsDialog } = useModal();
  const [formChanges, setFormChanges] = useState(form?.data);
  const [innerProductosEmisores, setInnerProductosEmisores] = useState([])
  const [finalForm, setFinalForm] = useState([])
  const [entity, setEntity] = useState({});
  const [emisor, setEmisor] = useState(0)
  const [isProductAbled, setIsProductAbled] = useState({
    cashIn: false,
    cashOut: false,
  })
  const [isAllowdEdition, setIsAllowdEdition] = useState(false)

  const { setAllowComponent } = useAuthPage();

  useEffect(() => {
    if (!isOpenProducts) {
      setEntity({})
      setEmisor(0)
      setIsProductAbled({
        cashIn: false,
        cashOut: false,
      })
      setInnerProductosEmisores([])
      setIsAllowdEdition(false)
    } else {
      let data = []
      let innerIsProductAbled = {
        cashIn: true,
        cashOut: true
      }
      if (entityArray.length) {
        let innerEntity = entityArray.find(
          (item) => item[item.nIdEmisor ? 'nIdEmisor' : 'nIdCadena'] === form.entity
        );
        if (innerEntity) {
          setEntity({ ...innerEntity });
          innerIsProductAbled = {
            cashIn: innerIsProductAbled.cashIn && JSON.parse(innerEntity.oLiquidacionCashIn).length,
            cashOut: innerIsProductAbled.cashOut && JSON.parse(innerEntity.oLiquidacionCashOut).length,
          }
        }
      }
      if (isCadena && emisores.length) {
        let innerEmisor = emisores[0].nIdEmisor
        setEmisor(emisor ? emisor : innerEmisor)
        setInnerProductosEmisores(productosEmisores.filter(per => per.nIdEmisor === (emisor ? emisor : innerEmisor)))
        data = [...form.data.filter(p => p.nIdEmisor === (emisor ? emisor : innerEmisor))]
      } else {
        data = [...form.data]
      }
      setIsProductAbled(innerIsProductAbled)
      let finalData = []

      data = data.map(item => {
        let p = finalData.find(p => p.sClaveProducto === item.sClaveProducto)
        if (p) {
          if (moment(item.dFecAlta).isAfter(p.dFecAlta)) {
            finalData = finalData.filter(fd => fd !== p)
            finalData.push(item)
          }
        } else {
          finalData.push(item)
        }
        return item
      })

      setFormChanges(finalData)
      setFinalForm(finalData)
      setIsAllowdEdition(setAllowComponent("editarProductos"))
    }
  }, [isOpenProducts, form]);


  /* useEffect(() => {
    let data = []
    if (isCadena && emisores.length) {
      let innerEmisor = emisores[0].nIdEmisor
      data = [...form.data.filter(p => p.nIdEmisor === (emisor ? emisor : innerEmisor))]
    } else {
      data = [...form.data]
    }
    let finalData = []

    data = data.map(item => {
      let p = finalData.find(p => p.sClaveProducto === item.sClaveProducto)
      if (p) {
        if (moment(item.dFecAlta).isAfter(p.dFecAlta)) {
          finalData = finalData.filter(fd => fd !== p)
          finalData.push(item)
        }
      } else {
        finalData.push(item)
      }
      return item
    })

    setFormChanges(finalData)
    setFinalForm(finalData)
  }, [form.data]); */

  const handleChangeEmisor = (emisor) => {
    if (emisores.length) {
      setEmisor(emisor)
      setInnerProductosEmisores(productosEmisores.filter(per => per.nIdEmisor === parseInt(emisor)))
      let data = form.data.filter(p => p.nIdEmisor === emisor)
      let finalData = []

      data = data.map(item => {
        let p = finalData.find(p => p.sClaveProducto === item.sClaveProducto)
        if (p) {
          if (moment(item.dFecAlta).isAfter(p.dFecAlta)) {
            finalData = finalData.filter(fd => fd !== p)
            finalData.push(item)
          }
        } else {
          finalData.push(item)
        }
        if (true) return item
      })

      setFormChanges(finalData)
      setFinalForm(finalData)
    }
  }

  const formatDate = (date) => {
    if (date && date[date.length - 1] === "Z") {
      return date.slice(0, -1);
    } else {
      return date;
    }
  };

  const checkAllBoxes = (flag) => {
    let arrForm = [...formChanges]

    productos.map(producto => {
      arrForm = handleChanges(arrForm, flag, producto.sClaveProducto)
    })
    setFormChanges(arrForm)

  }

  const checkBox = (flag, sClaveProducto) => {
    let arrForm = [...formChanges]

    arrForm = handleChanges(arrForm, flag, sClaveProducto)
    setFormChanges(arrForm)
  }

  const handleChanges = (arrForm, flag, sClaveProducto) => {

    arrForm = arrForm.filter(p => (p.sClaveProducto !== sClaveProducto))
    let formProduct = {}
    if (isCadena) {
      formProduct = finalForm.find(p => ((p.sClaveProducto === sClaveProducto && p.nIdEmisor === parseInt(emisor)) && (p.nIdEstatus === 1 || (!flag === p.nIdEstatus === 2))))
    } else {
      formProduct = finalForm.find(p => ((p.sClaveProducto === sClaveProducto) && (p.nIdEstatus === 1 || (!flag === p.nIdEstatus === 2))))
    }

    let isAlta = formProduct?.nIdEstatus === 1 && (!formProduct?.dFecBaja && formProduct?.dFecBaja !== "0000-00-00")

    if (formProduct) {
      if (flag) {
        arrForm.push({
          ...formProduct,
          dFecAlta: isAlta ? formProduct.dFecAlta : (moment().isBefore(formProduct.dFecBaja) ? formProduct.dFecBaja : moment().format("YYYY-MM-DD")),
          dFecBaja: isAlta ? formProduct.dFecBaja : null,
          dMinDate: isAlta ? null : new Date(formProduct.dFecBaja),
          sClaveProducto,
          nIdEstatus: 1,
          changed: isAlta !== flag,
        })
      } else {
        arrForm.push({
          ...formProduct,
          dFecBaja: !isAlta ? formProduct.dFecBaja : (moment().isBefore(formProduct.dFecAlta) ? formProduct.dFecAlta : moment().format("YYYY-MM-DD")),
          dMinDate: !isAlta ? null : new Date(formProduct.dFecAlta),
          sClaveProducto,
          nIdEstatus: 2,
          changed: isAlta !== flag,
        })
      }
    } else {
      if (flag) {
        arrForm.push({
          sClaveProducto,
          dFecAlta: moment().format('YYYY-MM-DD'),
          dFecBaja: null,
          dMinDate: null,
          nIdEstatus: 1,
          changed: true,
        })
      }
    }
    return arrForm
  }


  const isCheckedAllBoxes = () => {
    let flag = true;

    productos.map(producto => {
      let formProduct = formChanges.find(p => (p.sClaveProducto === producto.sClaveProducto && p.nIdEstatus === 1 && (!p.dFecBaja || p.dFecBaja === "0000-00-00")))
      if (!formProduct) flag = false
    })

    return flag
  }

  const setDateChange = (date, sClaveProducto, isAlta) => {
    let arrForm = [...formChanges]

    let formProduct = formChanges.find(p => p.sClaveProducto === sClaveProducto && ((isAlta && p.nIdEstatus === 1) || (!isAlta && p.nIdEstatus === 2)))

    arrForm = arrForm.filter(p => (p != formProduct))

    if (formProduct) {
      arrForm.push({
        ...formProduct,
        dFecAlta: isAlta ? date : formProduct.dFecAlta,
        dFecBaja: !isAlta ? date : formProduct.dFecBaja,
        changed: true,
      })
    }
    setFormChanges(arrForm)
  }

  const handleSubmit = () => {
    let arrForm = formChanges.filter(p => p.changed)
    if (arrForm.length) {
      onSubmit(arrForm.map(p => {
        return ({
          sClaveProducto: p.sClaveProducto,
          dFecAlta: p.dFecAlta,
          dFecBaja: p.dFecBaja,
          nIdEstatus: p.nIdEstatus,
          nIdEmisor: isCadena ? emisor : form.entity,
          nIdCadena: isCadena ? form.entity : 0
        })
      }))
    } else {
      setOpenToast(true, "No hay cambios que guardar")
    }
  }

  function disableDate(date, isEditing, formProduct) {

    let flag = false

    if (isEditing && formProduct?.dMinDate) {
      const currentDate = new Date(date)

      flag = currentDate < formProduct.dMinDate

      if (isCadena && !flag) {
        const prodEmisor = innerProductosEmisores.filter(per => per.sClaveProducto === formProduct.sClaveProducto).sort(function (a, b) { return new Date(a.dFecAlta) - new Date(b.dFecAlta) })

        for (let i = 0; i < prodEmisor.length; i++) {
          if (flag) break;

          const currentProd = prodEmisor[i];
          if (i === 0) flag = currentDate < new Date(currentProd.dFecAlta)

          if (!flag && i !== prodEmisor.length - 1) {
            const nextProd = prodEmisor[i + 1];
            if (nextProd) {
              flag = new Date(currentProd.dFecBaja) < currentDate && currentDate < new Date(nextProd.dFecAlta)
            }
          } else if (!flag && currentProd.dFecBaja) {
            flag = new Date(currentProd.dFecBaja) < currentDate
          }


        }
      }
    }
    return flag
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <Dialog
        open={isOpenConfirmProductsDialog}
        onClose={closeProductsDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {(isCadena ? "El comisionista" : "La EFR") + " no cuenta con productos dados de alta, para continuar debe dar de alta alguno. "}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeProductsDialog}>Cancelar</Button>
          <Button onClick={() => openProductsModal()} variant="contained">
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <ModalBase open={isOpenProducts} style={{ width: "700px" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ alignItems: isCadena ? "center" : "space-between", justifyContent: isCadena ? "center" : "space-between" }}>
            <Grid container item sm={8} style={{ alignItems: "center" }}>
              <Grid
                item
                style={{
                  color: "#00CD68",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                <WorkspacesTwoToneIcon fontSize={"large"} />
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  {entity.sNombreComercial}
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  Productos
                </Typography>
              </Grid>
            </Grid>
            {isCadena &&
              <Grid item sm={4}>
                <SearchInput
                  options={emisores}
                  fullWidth
                  value={emisor}
                  name={"emisor"}
                  label={"Emisor"}
                  getOptionLabel={"sNombreComercial"}
                  getIndexLabel={"nIdEmisor"}
                  onChange={(e) => handleChangeEmisor(e.target.value)}
                />
              </Grid>
            }
          </Grid>
          <Grid container spacing={2} style={{ padding: "20px 10px" }}>
            {(isCadena && emisor) || !isCadena ?
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ height: 50 }}>
                    <th style={{ textAlign: 'left', borderBottom: "1px solid #f1f1f1" }}>Productos</th>
                    <th style={{ borderBottom: "1px solid #f1f1f1" }}>Todos <Checkbox style={{ margin: 0, padding: 0 }} checked={isCheckedAllBoxes()} onChange={(e) => checkAllBoxes(e.target.checked)} disabled={!isAllowdEdition} /></th>
                    <th style={{ borderBottom: "1px solid #f1f1f1" }}></th>
                    <th style={{ borderBottom: "1px solid #f1f1f1" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {productos.map(producto => {

                    const formProduct = formChanges.find(p => (p.sClaveProducto === producto.sClaveProducto))
                    const isAlta = formProduct?.nIdEstatus === 1 && (!formProduct?.dFecBaja || formProduct?.dFecBaja === "0000-00-00")

                    const isAbled = (producto.nFlujoImporte === 1 && isProductAbled.cashIn) || (producto.nFlujoImporte === 0 && isProductAbled.cashOut)

                    if (isAbled && (!isCadena || (isCadena && innerProductosEmisores.find(per => per.sClaveProducto === producto?.sClaveProducto)))) {
                      return (
                        <tr style={{ height: 50 }}>
                          <td style={{ borderBottom: "1px solid #f1f1f1" }}>({producto.sClaveProducto}) {producto.sDescripcion}
                            {formProduct?.nIdSuspension ?
                                <p style={{ display: "inline-block", color: "#6200EE", background: `#6200EE15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0, fontSize: 13, marginLeft: 10 }} >Suspendido - ID: {formProduct.nIdSuspension }</p>
                              : <></>}

                          </td>
                          <td style={{ textAlign: 'center', borderBottom: "1px solid #f1f1f1" }}>
                            <Checkbox style={{ margin: 0, padding: 0 }} checked={isAlta} onChange={(e) => checkBox(e.target.checked, producto.sClaveProducto)} disabled={!isAllowdEdition} />
                          </td>
                          {formProduct && <td style={{ padding: 10, width: 150, borderBottom: "1px solid #f1f1f1" }}>
                            <DesktopDatePicker
                              label={`Fecha de ${true ? 'alta' : 'baja'}`}
                              inputFormat="YYYY-MM-DD"
                              required
                              disablePast={formProduct.changed && isAlta}
                              shouldDisableDate={(date) => disableDate(date, formProduct.changed && isAlta, formProduct)}
                              disabled={!formProduct || !formProduct.changed || !isAlta || !isAllowdEdition}
                              renderInput={(params) => (
                                <TextField {...params} size={"small"} fullWidth />
                              )}
                              value={moment(formatDate(formProduct.dFecAlta)).format('YYYY-MM-DD')}
                              onChange={(e) => setDateChange(moment(e["$d"]).format("YYYY-MM-DD"), producto.sClaveProducto, true)}
                            />
                          </td>
                          }

                          {formProduct && !isAlta &&
                            <td style={{ padding: 10, width: 150, borderBottom: "1px solid #f1f1f1" }}>
                              <DesktopDatePicker
                                label={`Fecha de ${false ? 'alta' : 'baja'}`}
                                inputFormat="YYYY-MM-DD"
                                required
                                disablePast={formProduct.changed}
                                shouldDisableDate={(date) => disableDate(date, formProduct.changed, formProduct)}
                                disabled={!formProduct || !formProduct.changed || !isAllowdEdition}
                                renderInput={(params) => (
                                  <TextField {...params} size={"small"} fullWidth />
                                )}
                                value={moment(formatDate(formProduct.dFecBaja)).format('YYYY-MM-DD')}
                                onChange={(e) => setDateChange(moment(e["$d"]).format("YYYY-MM-DD"), producto.sClaveProducto, false)}
                              />
                            </td>
                          }
                        </tr>
                      )
                    }

                  })}
                </tbody>
              </table>
              : <div>Seleccione un emisor para dar de alta o baja los productos</div>}
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={closeModalProducts} sx={{ color: "#00609C" }}>
              {formChanges.filter(p => p.changed).length ? "cancelar" : "cerrar"}
            </Button>
            {isAllowdEdition ? <Button onClick={handleSubmit} variant="contained">
              Guardar
            </Button> : <></>}

          </div>

        </Box>
      </ModalBase>

    </LocalizationProvider>
  );
};
