import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFacturas, getFacturasOrdenes, getFacturasValidas, insertFacturasOrdenes, validarFacturas } from '../slices/facturacion/facturacionThunk';
import { useModal } from './useModal';
//import { xml2js } from "xml-js";
import { closeFacturasOrdenes, closeImport, openFacturasOrdenes, openImport } from '../slices/ui/uiSlice';
import { signedURL, subirArchivoS3 } from '../slices/cadena/cadenaThunk';
import { facturasForm, importSucursalesForm } from '../helpers/forms';
import { getColonias, startLoadCadenas, startLoadColonias, startLoadEmisores, startLoadMotivosBaja } from '../slices/catalogo/catalgoThunk';
import { setFacturasBadge, setFacturasCadenas } from '../slices/facturacion/facturacionSlice';
import { saveAs } from 'file-saver';
import * as XLSX from "xlsx/xlsx.mjs";
import moment from 'moment';
import { importarSucursales, importarSucursalesBaja } from '../slices/sucursales/sucursalesThunk';
import { useAuthPage } from './useAuthPage';
import { SearchInput } from '../components/form/SearchInput';
import { SelectInput } from '../components/form/SelectInput';
import { regexCodigoPostal, regexNumExterior, regexNums } from '../helpers/regexs';
import { especificacionesAlta, especificacionesBaja, plantillaAlta, plantillaBaja } from '../helpers/valoresImportSucursal';

export const useImportSucursalesPage = () => {
  const dispatch = useDispatch();
  const scrollDownRef = useRef(null);
  const { isOpenImport } = useSelector((state) => state.ui);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFile, setSelectedFile] = useState(false);
  const [fileData, setFileData] = useState(false);
  const [filteredData, setFilteredData] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const { setOpenToast } = useModal();

  //---- UPLOADING
  const [progress, setProgress] = useState(0);
  const [log, setLog] = useState([]);

  const [form, setForm] = useState(importSucursalesForm);
  const [filtersLoaded, setFiltersLoaded] = useState(false)
  const [showErrorsView, setShowErrorsView] = useState(false);
  const [xlsData, setXlsData] = useState([]);
  const [filterXlsData, setFilterXlsData] = useState(0);
  const [isBaja, setIsBaja] = useState(false)
  const [cadenaName, setCadenaName] = useState("")
  const [customSpreadsheetData, setCustomSpreadsheetData] = useState({
    column: [],
    rows: [],
  })

  const { setAllowComponent } = useAuthPage();

  const {
    dataTable,
    facturasCadenas,
    ordenesPagoCadenas,
    facturasBadge
  } = useSelector((state) => state.facturacion);

  const {
    cadenas,
    motivosBaja
  } = useSelector((state) => state.catalogo);

  useEffect(() => {
    if (fileData && (form.nIdCadena || form.dFecInicioOperaciones != moment(form.dFecInicioOperaciones).format("YYYY-MM-DD"))) {
      setFilteredData(formatJson(fileData));
    }
  }, [form])

  useEffect(() => {
    showErrors()
  }, [filterXlsData])

  const resetData = () => {
    setActiveStep(0);
    setSelectedFile(false);
    setFilteredData(false);
    setIsOpenConfirmDialog(false);
    setShowErrorsView(false);
    setProgress(0);
    setLog([]);
    setXlsData([]);
    setForm(importSucursalesForm);
    setFileData(false);
    setFilterXlsData(0);
    setIsBaja(false)
    setCadenaName("")
    setCustomSpreadsheetData({
      column: [],
      rows: [],
    })
  }

  const loadInInit = async () => {
    await getFacturasData();
  }

  const getFacturasData = async () => {
    let resp = await dispatch(getFacturasValidas(form));
    if (resp.rows && form === facturasForm) {
      const innerResp = resp.rows.filter(row => row.nActor === 2 && row.nEstatusAsociar === 0)
      dispatch(setFacturasCadenas(innerResp))
      dispatch(setFacturasBadge(innerResp.length))
    }
  }

  const getFilters = async () => {
    if (!motivosBaja.length) {
      await dispatch(startLoadMotivosBaja());
    }
  }

  const onChange = (value) => {
    if (value.target) {
      value = { [value.target.name]: value.target.value }
    }
    const inputName = Object.keys(value);
    if (inputName.length && inputName[0] === "nIdCadena") {
      const innerCadena = cadenas?.rows?.find(cadena => cadena.nIdCadena === value.nIdCadena)
      setCadenaName(innerCadena ? innerCadena.sNombreComercial : "")
    }
    setForm({ ...form, ...value });
  };

  const handleOpenImport = () => {
    dispatch(openImport())
  };



  const handleCloseImport = async (isProcessEnded = false) => {
    dispatch(closeImport())
    if (isProcessEnded) {
      await getFacturasData();
    }
  };

  const confirmDelete = () => {
    setLog([]);
    setIsOpenConfirmDialog(true);
  };

  const postFacturasOrdenes = async (data) => {
    await dispatch(insertFacturasOrdenes(data)).then(async (resp) => {
      if (resp.nCodigo === 0) {
        setOpenToast(false, resp.sMensaje);
        dispatch(closeFacturasOrdenes());
        await getFacturasData();
      } else {
        setOpenToast(true, resp.sMensaje);
      }
    })
  }

  const importMethod = async () => {
    setIsOpenConfirmDialog(false);

    setShowErrorsView(false);
    setActiveStep(2);

    let innerProgress = 0;
    let innerLog = [];
    const loteLength = 10;
    let cantidadLotes = Math.ceil(filteredData.length / loteLength);
    const stepValue = 100 / cantidadLotes;
    setProgress(0);

    //Se recorren los lotes
    for (let i = 0; i < cantidadLotes; i++) {
      scrollToBottom();

      //Se obtiene el array del lote
      let loteArray = [];
      let initialIndex = i * loteLength;
      for (let j = 0; j < loteLength; j++) {
        let index = initialIndex + j;

        if (index < filteredData.length)
          loteArray.push({
            row: index,
            data: filteredData[index],
          });
      }

      await importRequest(initialIndex, loteArray).then((res) => {
        innerLog = [...innerLog, ...res];
        innerProgress += stepValue;
        //console.log(innerLog);
        setProgress(innerProgress);
        setLog(innerLog);

        scrollToBottom();
      });
    }
    setActiveStep(3);
    scrollToBottom();
  }

  const importRequest = async (initialIndex, loteArray) => {
    return new Promise(async (resolve, reject) => {
      const res = await dispatch(isBaja ?
        importarSucursalesBaja(initialIndex, loteArray.map((item) => item.data)) :
        importarSucursales(initialIndex, loteArray.map((item) => ({
          ...item.data,
          nNumExterno: !regexNums.test(item.data.nNumExterno) && regexNumExterior.test(item.data.nNumExterno) ? 0 : item.data.nNumExterno
        }))))

      if (res.ok) {
        resolve(res.sSucursales)
      } else {
        const errorArray = loteArray.map((item, key) => {
          return {
            nFila: initialIndex + key,
            nCodigo: 1,
            sMensaje: res.msg
          }
        })
        resolve(errorArray)
      }
    });
  };

  const handleDrop = (e) => {
    let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (file) {
      if (isExtensionAllowed(file.name)) {
        const reader = new FileReader();
        let finalData = [];
        let flagNoData = false;
        let flagHeaders = false;

        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          //workbook.SheetNames.map((sheetName) => {
          const worksheet = workbook.Sheets["Datos"] ?? workbook.Sheets["datos"];

          if (!worksheet) {
            setOpenToast(true, 'El archivo no cuenta con una hoja nombrada "Datos"');
          } else {
            const json = XLSX.utils.sheet_to_json(worksheet);
            if (json.length && isHeadersAllowed(json[0])) {
              finalData = json
            } else {
              if (!json.length) flagNoData = true;
              else flagHeaders = true;
            }
            //});

            if (finalData.length) {
              console.log(finalData)
              setSelectedFile(file);
              setFileData(finalData);
              setFilteredData(formatJson(finalData));
            } else {
              if (flagNoData) setOpenToast(true, "Archivo sin registros");
              if (flagHeaders)
                setOpenToast(
                  true,
                  "El archivo no tiene los encabezados obligatorios."
                );
            }
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        setOpenToast(true, "Extensión de archivo no compatible");
      }
    }
  };

  const formatJson = (json) => {
    let finalData = [];
    json?.map((row) => {
      if (isBaja) {
        let dFecBaja = row["Fecha de baja"]
        if (dFecBaja) {
          if (typeof dFecBaja === "string") {
            dFecBaja = moment(dFecBaja).format("YYYY-MM-DD");
          } else {
            const date = new Date(Math.round((dFecBaja - 25569) * 86400 * 1000));
            dFecBaja = date.toISOString().slice(0, -1).split("T")[0];
          }
        } else {
          dFecBaja = form.dFecInicioOperaciones;
        }

        finalData.push({
          ...row,
          sClaveSucursal: String(row["Clave de la sucursal"]).replace("'", "").trim(),
          nIdCadena: form.nIdCadena,
          sMotivoBaja: row["Motivo de baja"] ? String(row["Motivo de baja"]).replace("'", "").trim() : form.sMotivoBaja,
          dFecBaja
        });

      } else {
        let dFecInicioOperaciones = row["Fecha inicio operaciones"]
        if (dFecInicioOperaciones) {
          if (typeof dFecInicioOperaciones === "string") {
            dFecInicioOperaciones = moment(dFecInicioOperaciones).format("YYYY-MM-DD");
          } else {
            const date = new Date(Math.round((dFecInicioOperaciones - 25569) * 86400 * 1000));
            dFecInicioOperaciones = date.toISOString().slice(0, -1).split("T")[0];
          }
        } else {
          dFecInicioOperaciones = form.dFecInicioOperaciones;
        }

        finalData.push({
          ...row,
          sClaveSucursal: formatValue(row["Clave de la sucursal"]),
          sNombre: formatValue(row["Nombre"]),
          nIdCadena: formatValue(form.nIdCadena),
          sCalle: formatValue(row["Calle"]),
          nNumExterno: formatValue(row["Núm. exterior"]),
          sNumInterno: formatValue(row["Núm. interior"]),
          nCodigoPostal: formatValue(row["Código postal"]),
          sColonia: formatValue(row["Colonia"]),
          nLatitud: parseFloat(formatValue(row["Latitud"])).toFixed(6),
          nLongitud: parseFloat(formatValue(row["Longitud"])).toFixed(6),
          dFecInicioOperaciones: formatValue(dFecInicioOperaciones)
        });
      }


    });
    console.log(finalData)
    return finalData;
  };


  const formatValue = (value) => {
    return value ? String(value).replace("'", "").replace('"', "").trim() : ""
  }

  const isHeadersAllowed = (array1) => {
    let flag = true;

    const keysAr = Object.keys(array1);

    if (setAllowComponent("importarAltaSucursales")) {
      const headers = [
        "Clave de la sucursal",
        "Calle",
        "Núm. exterior",
        "Código postal",
        "Colonia",
        "Latitud",
        "Longitud"
      ];

      headers.map((header) => {
        if (!keysAr.includes(header)) {
          flag = false;
        }
      });

      setIsBaja(false)
    }


    if (
      (!flag && setAllowComponent("importarBajaSucursales"))
      || (!setAllowComponent("importarAltaSucursales") && setAllowComponent("importarBajaSucursales"))
    ) {
      flag = true;
      const headersBaja = [
        "Clave de la sucursal",
      ];

      headersBaja.map((header) => {
        if (!keysAr.includes(header)) {
          flag = false;
        }
      });
      setIsBaja(flag)
    }

    return flag;
  };

  const downloadPlantilla = (plantillaType = "alta") => {
    const workbook = XLSX.utils.book_new();
    var worksheet1 = XLSX.utils.aoa_to_sheet(plantillaType === "alta" ? plantillaAlta : plantillaBaja);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Datos");

    XLSX.utils.book_append_sheet(workbook, getEspecificacionesPlantilla(plantillaType), "Especificaciones");

    XLSX.writeFile(workbook, plantillaType === "alta" ? `plantilla_sucursales.xlsx` : `plantilla_sucursales_baja.xlsx`);
  };

  const getEspecificacionesPlantilla = (plantillaType) => {
    return XLSX.utils.aoa_to_sheet(plantillaType === "alta" ? especificacionesAlta : especificacionesBaja);
  }

  const isExtensionAllowed = (fileName) => {
    const extension = fileName.split(".").pop();
    return ["xls", "xlsx", "csv"].includes(extension);
  };

  const handleNext = async () => {
    const innerActiveStep = activeStep + 1;
    if (isBaja && innerActiveStep === 1) {
      await getFilters()
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const scrollToBottom = () => {
    scrollDownRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const showErrors = () => {
    if (filteredData) {
      const headers = isBaja ? [
        {
          value: "Identificador del comisionista",
          name: "nIdCadena",
          disableColumn: true,
          noExportable: true
        },
        {
          value: "Clave de la sucursal",
          name: "sClaveSucursal",
          disableColumn: false,
        },
        {
          value: "Motivo de baja",
          name: "sMotivoBaja",
          disableColumn: false,
        },
        {
          value: "Fecha de baja",
          name: "dFecBaja",
          disableColumn: false,
        },
        {
          value: "Mensaje",
          name: "sError",
          disableColumn: true,
        },
      ]
        : [
          {
            value: "1",
            name: "id",
            disableColumn: true,
            noExportable: true
          },
          {
            value: "Identificador del comisionista",
            name: "nIdCadena",
            disableColumn: true,
            noExportable: true,
            notShowing: true
          },
          {
            value: "Clave de la sucursal",
            name: "sClaveSucursal",
            disableColumn: false,
          },
          {
            value: "Nombre",
            name: "sNombre",
            disableColumn: false,
          },
          {
            value: "Calle",
            name: "sCalle",
            disableColumn: false,
          },
          {
            value: "Núm. exterior",
            name: "nNumExterno",
            disableColumn: false,
          },
          {
            value: "Núm. interior",
            name: "sNumInterno",
            disableColumn: false,
          },
          {
            value: "Código postal",
            name: "nCodigoPostal",
            disableColumn: false,
          },
          {
            value: "Colonia",
            name: "sColonia",
            disableColumn: false,
            type: "select",
            options: (row) => String(row.sColonias).trim().split(",")
          },
          {
            value: "Latitud",
            name: "nLatitud",
            disableColumn: false,
          },
          {
            value: "Longitud",
            name: "nLongitud",
            disableColumn: false,
          },
          {
            value: "Fecha inicio operaciones",
            name: "dFecInicioOperaciones",
            disableColumn: false,
          },
          {
            value: "Mensaje",
            name: "sError",
            disableColumn: true,
          },
        ];

      let xlsxDataInner = [headers.map((head) => ({ value: head.value, readOnly: true }))];
      filteredData.map((row, key) => {
        let error = log.find((l) => parseInt(l.nFila) === key);
        if (
          parseInt(filterXlsData) === 1 ||
          (parseInt(filterXlsData) === 0 && error && error.nCodigo !== 0) ||
          (parseInt(filterXlsData) === 2 && error && error.nCodigo === 0)
        ) {
          let innerArray = [];
          headers.map((header) => {
            let innerObject = {};
            if (header.name === "sError") {
              innerObject = {
                value: error?.sMensaje,
                readOnly: header.disableColumn,
              };
            } else {
              innerObject = {
                value: row[header.name],
                readOnly: header.disableColumn
              };
            }
            innerArray.push(innerObject);
          });
          xlsxDataInner.push(innerArray);
        }
      });
      setCustomSpreadsheetData({
        columns: headers.map(head => {
          return {
            value: head.value,
            name: head.name,
            disableColumn: head.disableColumn,
            type: head.type ?? "text",
            options: head.options,
            notShowing: head.notShowing
          }
        }),
        rows: filteredData.map((row, key) => {
          let error = log.find((l) => parseInt(l.nFila) === key);
          return {
            id: key + 2,
            ...row,
            ...(error ? { ...error, sError: error.sMensaje } : {}),
            sColonia: row.sColonia
          }
        })
      })
      setXlsData(xlsxDataInner);
      setShowErrorsView(true);
    }
  };

  const downloadErrorsFile = () => {
    const data = [];

    xlsData.map((row, key) => {
      let innerData = [];
      row.map((r, j) => {
        if (!xlsData[0][j].noExportable) {
          if (key) {
            if (xlsData[0][j].name === "dFecha") {
              const dFecha = moment(r.value, "YYYY-MM-DD").format("DDMMYYYY");
              innerData.push(dFecha);
            } else {
              innerData.push(r.value);
            }
          } else {
            innerData.push(r.value);
          }
        }
      });
      data.push(innerData);
    });

    const workbook = XLSX.utils.book_new();
    var worksheet1 = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Datos");
    XLSX.writeFile(workbook, `resultado.xlsx`);
  };

  const processErrors = () => {
    setFilteredData(customSpreadsheetData.rows);
    confirmDelete();
  };

  const handleSpreadsheetData = async (rows, changes) => {
    const {
      column,
      indexes = []
    } = changes
    console.log(changes)
    const key = column?.key
    if (key) {
      await Promise.all(indexes.map(async (index) => {
        rows[index].sErrorKeys = rows[index].sErrorKeys.split(",").filter(k => k !== key).join(",")


        if (key === "nCodigoPostal" && regexCodigoPostal.test(rows[index].nCodigoPostal)) {
          //traer las colonias de ese código postal
          const colonias = await dispatch(getColonias(rows[index].nCodigoPostal));
          rows[index].sColonias = colonias?.map((colonia) => colonia.sNombreColonia).join(",")
          rows[index].sColonia = ""

        }
      }))
    }

    setCustomSpreadsheetData({
      ...customSpreadsheetData,
      rows: rows
    })
  }

  return {
    isOpenConfirmDialog,
    setIsOpenConfirmDialog,
    fileData,
    importMethod,
    isOpenImport,
    activeStep,
    handleDrop,
    progress,
    log,
    scrollDownRef,
    handleBack,
    confirmDelete,
    handleNext,
    handleCloseImport,
    handleOpenImport,
    loadInInit,
    dataTable,
    onChange,
    getFacturasData,
    cadenas,
    getFilters,
    form,
    facturasCadenas,
    ordenesPagoCadenas,
    facturasBadge,
    postFacturasOrdenes,
    selectedFile,
    setSelectedFile,
    fileData,
    setFileData,
    filteredData,
    downloadPlantilla,

    setShowErrorsView,
    showErrorsView,
    filterXlsData,
    setFilterXlsData,
    xlsData,
    setXlsData,
    showErrors,
    downloadErrorsFile,
    processErrors,
    resetData,
    isBaja,
    motivosBaja,
    cadenaName,
    setAllowComponent,
    customSpreadsheetData,
    handleSpreadsheetData
  };
};


