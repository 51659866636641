import { ModalBase } from './modal/ModalBase';
import { useModal } from '../hooks/useModal';
import { FormConciliacion } from './FormConciliacion';

export const ConciliacionModal = () => {
  const { isOpenConciliacion, closeModalConciliacion } = useModal();
  return (
    <ModalBase open={isOpenConciliacion} onClose={closeModalConciliacion}>
      <FormConciliacion />
    </ModalBase>
  );
};
