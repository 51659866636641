import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import {
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import { Table } from "../components/table/Table";
import { useEFRTable } from "../hooks/useEFRTable";
import { ModalListRoute } from "../components/modal/ModalListRoute";
import { ModalRoute } from "../components/modal/ModalRoute";
//configuracion de conciliacion
import { ConciliacionModal } from "../components/ConciliacionModal";
import { useModalConciliacion } from "../hooks/useModalConciliacion";
import { useDispatch } from "react-redux";
import { useModal } from "../hooks/useModal";

import { ModalEFRConfirmacion } from "../components/modal/ModalEFRConfirmacion";

import { ModalDeleteEntity } from "../components/modal/ModalDeleteEntity";
import { ModalProducts } from "../components/modal/ModalProducts";
import EnhancedTable from "../components/table/EnhancedTable";
import { startLoadMotivosBaja } from "../slices/catalogo/catalgoThunk";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import DeleteIcon from '@mui/icons-material/Delete';
import RouteIcon from "@mui/icons-material/Route";
import StoreMallDirectory from '@mui/icons-material/StoreMallDirectory';
import { ModalComisionistas } from "../components/modal/ModalComisionistas";
import PercentIcon from '@mui/icons-material/Percent';
import { ModalComisionesEscalonadas } from "../components/modal/ModalComisionesEscalonadas";
import { Hub, Key, Password, ReceiptLong } from "@mui/icons-material";
import { ModalEnvioOperaciones } from "../components/modal/ModalEnvioOperaciones";
import { loading } from "../slices/ui/uiSlice";
import { ModalConexionesSFTP } from "../components/modal/ModalConexionesSFTP";

const iconTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#00609C",
      dark: "#053e85",
    },
    neutral: {
      main: "#00CD68",
      light: "#053e85",
      dark: "#053e85",
      contrastText: "#fff",
    },
  },
});

const colorsTab = ["#E7B10A", "#00CD68", "#e53e3e", "#053e85", "#6200EE"]

export const VerEFRPage = () => {
  const { setNameSection } = useMenu();
  const { setAllowComponent } = useAuthPage();
  const { openModalConciliacion } = useModalConciliacion();
  const { openModalConfirmacion, setOpenToast, isTableLoading, openModalComisionesEscalonadas, openModalEnvioOperaciones, openModalConexionesSFTP } = useModal();
  const dispatch = useDispatch();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedEmisor, setSelectedEmisor] = useState({});

  const {
    loadInInit,
    emisores,
    onClickAdd,
    getRoutes,
    onChange,
    form,
    openModalRoute,
    productos,
    onChangeInput,
    saveRoute,
    deleteRoute,
    motivosBaja,
    deleteEmisor,
    openProductsModal,
    efrProductos,
    submitProducts,
    closeConfirmProduct,
    productosEmisor,
    openModalComisionistas,
  } = useEFRTable();

  const estatusEFR = {
    inactivo: 0,
    activo: 1,
    baja: 2,
    pendiente: 3
  }

  const triggerDeleteDialog = async (nIdEmisor) => {
    let item = emisores.rows.find((emisor) => emisor.nIdEmisor === nIdEmisor);
    if (item) {
      setSelectedEmisor(item);
      if (!motivosBaja.length) await dispatch(startLoadMotivosBaja());
      setShowDeleteDialog(true);
    }
  };

  useEffect(() => {
    setNameSection("Entidades Financieras Reguladas");
  }, [setNameSection]);

  useEffect(() => {
    loadInInit();
  }, []);


  return (
    <ThemeProvider theme={iconTheme}>
      <ModalDeleteEntity
        entidad={selectedEmisor}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        motivosBaja={motivosBaja}
        deleteFunction={deleteEmisor}
      />
      <ModalConexionesSFTP />
      <ModalEnvioOperaciones />
      <ModalComisionesEscalonadas />
      <ModalProducts
        form={efrProductos}
        entityArray={emisores.rows}
        productos={productos}
        onSubmit={submitProducts}
        openProductsModal={openProductsModal}
      />
      <ModalEFRConfirmacion key={"efr-modalConfirmacion"} />
      <ConciliacionModal />
      <ModalListRoute
        openModalRoute={openModalRoute}
        deleteRoute={deleteRoute}
        form={form}
        emisores={emisores.rows}
      />
      <ModalComisionistas
        openModalComisionistas={openModalComisionistas}
        deleteRoute={deleteRoute}
        form={form}
        emisores={emisores.rows}
      />
      <ModalRoute
        onChange={onChange}
        form={form}
        productos={productos}
        onChangeInput={onChangeInput}
        emisores={emisores.rows}
        saveRoute={saveRoute}
        productosEmisor={productosEmisor}
      />
      <EnhancedTable
        table={{
          ...emisores,
          columns: [
            ...emisores.columns,
            {
              field: 'sEstatusEFR', headerName: 'Estado', type: 'render', flex: 1, renderFunction: (row) => (
                <p style={{ display: "inline-block", color: colorsTab[row.nIdEstatusEFR], background: `${colorsTab[row.nIdEstatusEFR]}15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0, fontSize: 13 }} >{row.sEstatusEFR}</p>
              )
            },
          ]
        }}
        add={setAllowComponent("agregarEFR")}
        onAddFunction={onClickAdd}
        loading={isTableLoading}
        rowId={"nIdEmisor"}
        buttons={[
          {
            label: "Ver",
            icon: <VisibilityIcon fontSize={"small"} />,
            onClick: (id) => openModalConfirmacion(id),
            showButton: setAllowComponent('verEFR')
          },
          {
            label: "Productos",
            icon: <WorkspacesIcon fontSize={"small"} />,
            onClick: (id) => openProductsModal(id),
            showButton: setAllowComponent('verProductos')
          },
          {
            label: "Rutas",
            icon: <RouteIcon fontSize={"small"} />,
            onClick: (id) => getRoutes(id),
            showButton: setAllowComponent('verRutas')
          },
          {
            label: "Comisionistas",
            icon: <StoreMallDirectory fontSize={"small"} />,
            onClick: (id) => openModalComisionistas(id),
            showButton: setAllowComponent('verComisionistas')
          },
          {
            label: "Comisiones escalonadas",
            icon: <PercentIcon fontSize={"small"} />,
            onClick: (id,row) => openModalComisionesEscalonadas(row),
            showButton: setAllowComponent('verEscalonadas')
          },
          {
            label: "Conexiones SFTP",
            icon: <Hub fontSize={"small"} />,
            onClick: (id,row) => openModalConexionesSFTP({nIdEmisor: id}),
            showButton: setAllowComponent("modalConexionesSFTP")
          },
          {
            label: "Eliminar",
            icon: <DeleteIcon fontSize={"small"} />,
            onClick: async (id) => await triggerDeleteDialog(id),
            showButton: setAllowComponent('eliminarEFR')
          }
        ]}
      />
    </ThemeProvider>
  );
};
