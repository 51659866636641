import { Download, History, PictureAsPdf, Summarize } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModalBase } from './ModalBase';
import EnhancedTable from '../table/EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import ButtonDropdown from '../ButtonDropdown';
import { useExportFile } from '../../hooks/useExportFile';
import { useEffect, useState } from 'react';
import { getEnvioCredencialesBitacora } from '../../slices/efr/efrThunk';
import moment from 'moment';
import { getArchivosBitacora, getConexionesBitacora } from '../../slices/conexionesSFTP/conexionesSFTPThunk';
import { useModal } from '../../hooks/useModal';
import { getBitacoraConciliacion } from '../../slices/conciliacion/conciliacionThunk';

const columns = [
  { field: 'nIdBitacoraConciliacion', headerName: 'ID', flex: 1, style: {width: 30} },
  { field: 'dFecRegistro', headerName: 'Fecha bitácora', flex: 2, type: "datetime", style: { width: 50 } },
  
  {
    field: 'sEstatusConciliacion', headerName: 'Estado conciliación', type: 'render', flex: 2, renderFunction: (row) => (
      <Grid style={{ whiteSpace: "break-spaces" }}>
        <p style={{ display: "inline-block", color: colorsTab[row.nIdEstatusConciliacion - 1], background: `${colorsTab[row.nIdEstatusConciliacion - 1]}15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0, fontSize: 13 }} >{row.sEstatusConciliacion}</p>
      </Grid>
    )
  },
  { field: 'sComentario', headerName: 'Comentario', flex: 4 }
]


const colorsTab = ["#053e85", "#E7B10A", "#00CD68", "#ff9800", "#e53e3e"]

export const ModalBitacoraConciliacion = () => {



  const { isOpenBitacoraConciliacion, isTableModalLoading } = useSelector((state) => state.ui);
  const { closeModalBitacoraConciliacion } = useModal()
  const dispatch = useDispatch();
  const { downloadFile } = useExportFile()


  const [dataTable, setDataTable] = useState({
    rows: [],
    columns
  })

  useEffect(() => {
    if (isOpenBitacoraConciliacion.open) {
      getData()
    } else {
      setDataTable({
        rows: [],
        columns
      })
    }
  }, [isOpenBitacoraConciliacion.open])

  const getData = async () => {
    const data = await dispatch(getBitacoraConciliacion(isOpenBitacoraConciliacion))

    setDataTable({
      rows: data,
      columns,
    })
  }

  const downloadBitacora = (format) => {
    let {
      rows = [],
      columns = []
    } = dataTable
    const dFecha = moment(isOpenBitacoraConciliacion.dFechaConciliacion).format("YYYY-MM-DD")

    downloadFile(
      rows.sort((a, b) => parseFloat(a.nIdBitacoraConciliacion) - parseFloat(b.nIdBitacoraConciliacion)),
      columns.map(c => ({ ...c, format: c.type })),
      format,
      `Bitácora de conciliación (${isOpenBitacoraConciliacion.sNombreComercial}, ${dFecha})`,
      `Bitácora de conciliación (${isOpenBitacoraConciliacion.sNombreComercial})`,
      `Fecha: ${dFecha}`)
  }



  return (<>
    <ModalBase open={isOpenBitacoraConciliacion.open} title="" style={{ width: "1050px", height: 'auto' }}>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <EnhancedTable
          rowId={"nIdBitacoraConciliacion"}
          table={dataTable}
          disableButtons
          isModalTable
          disablePathParameters
          isLoading={isTableModalLoading}
          icon={<History fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />}
          subtitle={`Bitácora de conciliación (${moment(isOpenBitacoraConciliacion.dFechaConciliacion).format("YYYY-MM-DD")})`}
          title={isOpenBitacoraConciliacion?.sNombreComercial}
          extraButtons={<ButtonDropdown
            startIcon={<Download />}
            label="Exportar"
            options={[
              { startIcon: <PictureAsPdf />, label: "PDF", action: () => downloadBitacora("pdf") },
              { startIcon: <Summarize />, label: "Excel", action: () => downloadBitacora("excel") },
            ]}
          />}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end'
          }}
        >
          <Button onClick={closeModalBitacoraConciliacion} color="primary">
            Volver
          </Button>
        </div>
      </Box>
    </ModalBase>
  </>
  );
};

