import {
  startLoadCadenas,
  startLoadEmisores,
  startLoadMotivosBaja,
  startLoadProductos,
  startLoadRutas,
} from "../slices/catalogo/catalgoThunk";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { permissionForm } from "../helpers/forms";
import {
  getPermissionsData,
  getProducts,
  getProductsEmisores,
  insertBaja,
  insertPermiso,
  postProducts,
  startLoadEditarCadena,
  updatePermiso,
} from "../slices/cadena/cadenaThunk";
import { closeConfirmProductsDialog, closePermission, openConfirmProductsDialog, openPermission, openProducts } from "../slices/ui/uiSlice";
import { getRoutesData } from "../slices/emisor/emisorThunk";
import { useModal } from "./useModal";

export const useCadenasTable = () => {
  const [form, setForm] = useState(permissionForm);
  const [cadenaProductos, setCadenaProductos] = useState({
    data: [],
    entity: 0
  });
  const { cadenas, rutas, motivosBaja, productos } = useSelector(
    (state) => state.catalogo
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setOpenToast, openModalDelte, closeModalProducts, openModalEnvioOperaciones } = useModal();
  const [productoEmisoresResp, setProductoEmisoresResp] = useState([])
  const [productosEmisores, setProductosEmisores] = useState([])
  const [productosCadena, setProductosCadena] = useState([])
  const [emisores, setEmisores] = useState({
    rows: [],
    columns: []
  })

  const onClickAdd = () => {
    navigate("/altas-cadenas");
  };

  

  const loadInInit = async () => {
    await dispatch(startLoadCadenas());
  };

  const getCadenaRutas = async (nIdCadena) => { 
    const resp = await dispatch(startLoadRutas(0, 0, nIdCadena, 1));
    
    const emisores = []
      resp.rows.map(r => {
        if (!emisores.some(emisor => emisor.nIdEmisor === r.nIdEmisor)) {
          emisores.push({
            nIdEmisor: r.nIdEmisor,
            sNombreComercial: r.sNombreComercial
          });
        }
      })
      console.log(emisores)
      setEmisores({ columns: [], rows: emisores });
  }

  const getPermissions = async (cadena, permiso = 0, estatus = -1) => {
    onChange(null, "nIdCadena", cadena);

    await dispatch(getProducts(cadena, 0)).then(async(productos) => {
      if(productos.length){
        await dispatch(getPermissionsData(permiso, cadena, estatus));
        await getCadenaRutas(cadena);
        setProductosEmisores(productoEmisoresResp.filter(per => per.nIdCadena === cadena))
        setProductosCadena(productos)
      }else{
        dispatch(openConfirmProductsDialog());
      }
    })

  };

  const onChange = (e, name = null, value = null) => {
    
    const inputName =
      name !== null && name !== undefined ? name : e.target.name;
    const inputValue =
      value !== null && value !== undefined ? value : e.target.value;
    const switchOptions = ["nDetalle"];

    if (switchOptions.includes(inputName)) {
      setForm({ ...form, [inputName]: e.target.checked });
    } else {
      setForm({ ...form, [inputName]: inputValue });
    }
  };

  const onChangeInput = async (value) => {
    const keys = Object.keys(value)
    const switchOptions = ["nImpComCadena", "nImpComCliente", "nImpComEspecial"];
    if (switchOptions.includes(keys[0]) && (!value[keys[0]] || value[keys[0]] < 0)) {
      setForm({...form, [keys[0]]: 0 });
    } else {
      setForm({ ...form, ...value });
      
    }
  };

  const onChangeForm = (innerForm) => {
    setForm({ ...form, ...innerForm });
  };

  const openModalPermission = async (modalType = "add", permiso = 0) => {
    if (modalType === "edit" || modalType === "view") {
      await dispatch(getPermissionsData(permiso, form.nIdCadena, 1, true)).then(
        (result) => setForm({ ...form, ...result.rows[0], modalType })
      );
      await dispatch(openPermission());
    } else {
      setForm({ ...permissionForm, nIdCadena: form.nIdCadena });
      dispatch(openPermission());
    }
  };

  const deletePermission = async (permiso) => {
    await dispatch(
      updatePermiso(
        {
          ...form,
          ...(
            await dispatch(getPermissionsData(permiso, form.nIdCadena, 1, true))
          ).rows[0],
        },
        "delete"
      )
    ).then((resp) => openToast(resp));
  };

  const savePermission = async () => {
    switch (form.modalType) {
      case "add":
        await dispatch(insertPermiso(form)).then((resp) => openToast(resp));
        break;
      case "edit":
        await dispatch(updatePermiso(form, "edit")).then((resp) =>
          openToast(resp)
        );
        break;
      default:
        break;
    }
  };

  const deleteCadena = async (form) => {
    const section = 'bajaCadena';
    const formUpdate = {nIdCadena : form.nIdCadena, nIdEstatusCadena : 2, datosBaja:{nIdMotivoBaja : form.nIdMotivoBaja , dFecBaja : form.dFecBaja} }
    await dispatch(startLoadEditarCadena(formUpdate,section)).then(async(resp) => {
      setOpenToast(!resp.ok, resp.msg)
      await dispatch(startLoadCadenas())
    });

  };

  const openToast = async(resp) => {
    console.log(resp);
    if (!resp.ok) {
      setOpenToast(true, resp.msg);
      return;
    } else {
      setOpenToast(false, resp.msg);
      await dispatch(getPermissionsData(0, form.nIdCadena, 1));
      dispatch(closePermission());
    }
  };

  const getProductsEmisor = async () => {
    if (productoEmisoresResp.length === 0) {
      const resp = await dispatch(getProductsEmisores())
      const emisores = []
      resp.map(r => {
        if (!emisores.some(emisor => emisor.nIdEmisor === r.nIdEmisor)) {
          emisores.push({
            nIdEmisor: r.nIdEmisor,
            sNombreComercial: r.sNombreComercial
          });
        }
      })

      setEmisores({ columns: [], rows: emisores });
      setProductoEmisoresResp(resp)
      return resp
    }
    return productoEmisoresResp
  }

  const openProductsModal = async (value = null) => {

    if(!value) dispatch(closeConfirmProductsDialog());

    await dispatch(startLoadProductos());
    const productosEmisores = await getProductsEmisor();
    await dispatch(getProducts(value ? value : form.nIdCadena)).then((resp) => {
      setCadenaProductos({
        data: resp,
        entity: value ? value : form.nIdCadena
      })
      setProductosEmisores(productosEmisores)
    });
    dispatch(openProducts());
  }

  const submitProducts = async (arr) => {
    await dispatch(postProducts(arr)).then(async (resp) => {
      if (resp.ok) {
        await dispatch(getProducts(cadenaProductos.entity)).then((resp) => {
          setCadenaProductos({
            ...cadenaProductos,
            data: resp,
          })
        });
        setOpenToast(false, resp.msg)
        dispatch(closeModalProducts())
      } else {
        setOpenToast(true, resp.msg)
      }
    });
    dispatch(openProducts());
  }

  return {
    loadInInit,
    cadenas,
    onClickAdd,
    form,
    emisores,
    getPermissions,
    onChange,
    onChangeInput,
    openModalPermission,
    rutas,
    motivosBaja,
    deletePermission,
    savePermission,
    onChangeForm,
    deleteCadena,
    cadenaProductos,
    openProductsModal,
    submitProducts,
    productos,
    productosEmisores,
    productosCadena,
    openModalEnvioOperaciones
  };
};
