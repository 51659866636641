import { Close, PersonAdd, Person, Visibility, VisibilityOff, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Alert, Button, Checkbox, Collapse, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TextInput } from '../form/TextInput';
import { ModalBase } from './ModalBase';
import { regexEmail, regexLatitud, regexLongitud, regexNumExterior, regexNums, regexPassword, regexTelefono } from '../../helpers/regexs';
import { SelectInput } from '../form/SelectInput';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BlueSwitch } from '../../helpers/stylesMaterial';
import { useAuthPage } from '../../hooks/useAuthPage';
import { ConfirmDialog } from './ConfirmDialog';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { SearchInput } from '../form/SearchInput';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import moment from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "dayjs/locale/es-mx";
import { Form } from 'react-router-dom';

export const ModalSucursal = (props) => {

  const {
    isOpenModal = false,
    modalType = "add",
    form = {},
    onChange,
    handleSubmit,
    handleSubmitChangeEstatus,
    handleCloseModal,
    enableEditModal,
    disableEditModal
  } = props;

  const {
    cadenas,
    ciudades = [],
    estados = [],
    colonias = []
  } = useSelector((state) => state.catalogo)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const { setAllowComponent } = useAuthPage();


  const showTitleButton = () => {
    if (modalType === 'view' && setAllowComponent("editarSucursales") && form.nIdEstatus !== 2) return <Button variant='contained' onClick={() => enableEditModal(form)}
      startIcon={<EditIcon />}>Editar</Button>;
    if (modalType === 'edit' && setAllowComponent("estatusSucursales") && [0, 1].includes(form.nIdEstatus)) {
      return <Button
        onClick={() => setShowConfirmDialog(true)}
        variant={"contained"}
        color={form.nIdEstatus !== 1 ? "neutral" : "error"}
        startIcon={form.nIdEstatus !== 1 ? <CheckCircleIcon /> : <UnpublishedIcon />}
      >
        {form.nIdEstatus !== 1 ? 'Habilitar sucursal' : 'Deshabilitar sucursal'}
      </Button>;
    }
    /* if (modalType === 'add') return (<Button variant='contained' onClick={handleOpenImport}
      startIcon={<UploadFileIcon />}>Cargar archivo</Button>); */
  }

  const handleConfirmDialog = async () => {
    setShowConfirmDialog(false)
    await handleSubmitChangeEstatus()
  }

  const getFormattedDate = (value) => {
    return value[value.length - 1] === "Z" ? value.slice(0, -1) : value
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <ConfirmDialog
        isOpenDialog={showConfirmDialog}
        onCloseDialog={() => setShowConfirmDialog(false)}
        onConfimDialog={handleConfirmDialog}
        message={`¿Estas seguro/a de ${form.nIdEstatus === 1 ? 'deshabilitar' : 'habilitar'} la sucursal?`}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ModalBase open={isOpenModal} title="" style={{ width: '1050px', height: 'auto' }}>
        <Box
          sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <TitleModal title={modalType === "add" ? "Sucursal" : form.sNombre} typeOperation={modalType} extraButton={showTitleButton()} />

          <Grid container spacing={2} sx={{ mt: 1, mb: 2, maxHeight: 800, overflow: 'auto' }}>
            <Grid item xs={12}>
              <Typography variant="h6" color={"primary"}>Datos de sucursal</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                id="sClaveSucursal"
                name="sClaveSucursal"
                label="Identificador de la sucursal"
                value={form.sClaveSucursal}
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
                require
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                id="sNombre"
                name="sNombre"
                label="Nombre"
                value={form.sNombre}
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {modalType === 'view' ?
                <TextInput
                  label="Comisionista"
                  value={form.sNombreComercial}
                  disabled
                />
                :
                <SearchInput
                  options={cadenas.rows}
                  value={form.nIdCadena}
                  name={"nIdCadena"}
                  label={"Comisionista"}
                  getOptionLabel={"sNombreComercial"}
                  getIndexLabel={"nIdCadena"}
                  disabled={modalType === 'view'}
                  onChange={onChange}
                />
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <DesktopDatePicker
                label="Fecha de inicio de operaciones"
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => <TextField {...params} fullWidth />}
                disablePast={modalType === 'add' || !moment(form.dFecInicioOperaciones).isSameOrBefore(moment())}
                disabled={modalType === 'view' || modalType === 'edit' && moment(form.dFecInicioOperaciones).isSameOrBefore(moment())}
                value={getFormattedDate(form.dFecInicioOperaciones)}
                onChange={(e) => onChange({ dFecInicioOperaciones: moment(e['$d']).format('YYYY-MM-DD') })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color={"primary"}>Dirección</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                id="sCalle"
                name="sCalle"
                label="Calle"
                value={form.sCalle}
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
                caracteres="100"
                require
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                id="nNumExterno"
                name="nNumExterno"
                label="Núm. Exterior"
                value={form.nNumExterno === 0 ? "S/N" : form.nNumExterno }
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
                erroMsg="Formato incorrecto."
                caracteres="30"
                require
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                id="sNumInterno"
                name="sNumInterno"
                label="Núm. Interior"
                value={form.sNumInterno}
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
                caracteres="10"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                id="nCodigoPostal"
                name="nCodigoPostal"
                label="Código postal"
                value={form.nCodigoPostal}
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
                caracteres="5"
                require
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {modalType === 'view' ?
                <TextInput
                  label="Estado"
                  value={form.sEstado}
                  disabled
                />
                :
                <SearchInput
                  id="estado"
                  name="estado"
                  onChange={onChange}
                  label="Estado"
                  options={estados}
                  value={form.estado}
                  getOptionLabel={"text"}
                  getIndexLabel={"value"}
                  disabled={modalType === 'view'}
                />
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              {modalType === 'view' ?
                <TextInput
                  label="Municipio"
                  value={form.sMunicipio}
                  disabled
                />
                :
                <SearchInput
                  id="ciudad"
                  name="ciudad"
                  onChange={onChange}
                  label="Municipio"
                  options={ciudades}
                  value={form.ciudad}
                  getOptionLabel={"text"}
                  getIndexLabel={"value"}
                  disabled={modalType === 'view'}
                />
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchInput
                id="colonia"
                name="colonia"
                onChange={onChange}
                label="Colonia"
                options={modalType !== 'view' ? colonias : [{ value: 0, text: form.sNombreColonia }]}
                value={modalType !== 'view' ? String(form.colonia) : 0}
                getOptionLabel={"text"}
                getIndexLabel={"value"}
                disabled={modalType === 'view'}
              />
            </Grid>
            {modalType !== 'add' &&
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="nClaveDireccion"
                  name="nClaveDireccion"
                  label="Clave de la dirección"
                  type="number"
                  value={modalType === 'edit' && parseInt(form.colonia) !== parseInt(form.nIdColonia) ? "" :form.nClaveDireccion}
                  onChangeRoot={onChange}
                  disabled={modalType === 'view' || parseInt(form.colonia) !== parseInt(form.nIdColonia)}
                  expresionRegular={regexNums}
                  require
                />
              </Grid>
            }
            <Grid item xs={12} sm={6}>
              <TextInput
                id="nLatitud"
                name="nLatitud"
                label="Latitud"
                type="number"
                value={form.nLatitud}
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
                expresionRegular={regexLatitud}
                erroMsg="Formato incorrecto."
                require
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                id="nLongitud"
                name="nLongitud"
                label="Longitud"
                type="number"
                value={form.nLongitud}
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
                expresionRegular={regexLongitud}
                erroMsg="Formato incorrecto."
                require
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'end'
            }}
          >
            <Button onClick={modalType === "edit" ? disableEditModal : handleCloseModal} color="primary">
              {modalType === "edit" ? "Cancelar" : "Cerrar"}
            </Button>
            {modalType !== 'view' &&
              <Button
                onClick={handleSubmit}
                color="primary"
                variant='contained'
              >
                Guardar
              </Button>
            }
          </div>
        </Box>
      </ModalBase>
    </LocalizationProvider>
  );
};

const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <StorefrontIcon fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {typeOperation === 'add' ? 'Agregar' : typeOperation === 'edit' ? 'Editar sucursal' : 'Ver sucursal'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};

