import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loaded: false,
  giros: [],
  procesos: [],
  documentos: [],
  bancos: [],
  retenciones: [],
  comisiones: [],
  integraciones: [],
  cadenas: {
    columns: [],
    rows: []
  },
  tiposLiquidacion: [],
  ciudades: [],
  estados: [],
  colonias: [],
  productos: [],
  catProductos: [],
  cfdis: [],
  formasPago: [],
  metodosPago: [],
  regimensFiscal: [],
  productoServicios: [],
  unidades: [],
  rutas: {
    columns: [],
    rows: []
  },
  estatusPago: [],
  emisores: {
    columns: [],
    rows: []
  },
  escenariosContables: [],
  motivosBaja: [],
  identificadores: [],
  estatusFacturacion: [],
  estatusConciliacion: [],
  perfiles: [],
  opciones: [],
  secciones: [],
  integradores: [],
  sectoresCNBV: [],
  estatusCNBV: [],
  cuentasContables: [],
  tareas: [],
  archivosSFTP: [],
  carpetasSFTP: [],
  tiposActor: []
};

export const catalagoSlice = createSlice({
  name: 'catalogo',
  initialState,
  reducers: {
    setGiros: (state, action) => {
      state.giros = [...action.payload];
    },
    setProcesos: (state, action) => {
      state.procesos = [...action.payload];
    },
    setDocumentos: (state, action) => {
      state.documentos = [...action.payload];
    },
    setBancos: (state, action) => {
      state.bancos = [...action.payload];
    },
    setRetenciones: (state, action) => {
      state.retenciones = [...action.payload];
    },
    setComsiones: (state, action) => {
      state.comisiones = [...action.payload];
    },
    setIntegraciones: (state, action) => {
      state.integraciones = [...action.payload];
    },
    setCadenas: (state, action) => {
      state.cadenas = { ...action.payload };
    },
    setTiposLiquidacion: (state, action) => {
      state.tiposLiquidacion = [...action.payload];
    },
    setEstados: (state, action) => {
      state.estados = [...action.payload];
    },
    setCiudades: (state, action) => {
      state.ciudades = [...action.payload];
    },
    setColonias: (state, action) => {
      state.colonias = [...action.payload];
    },
    setProductos: (state, action) => {
      state.productos = [...action.payload];
    },
    setCatProductos: (state, action) => {
      state.catProductos = [...action.payload];
    },
    setPerfiles: (state, action) => {
      state.perfiles = [...action.payload];
    },
    setOpciones: (state, action) => {
      state.opciones = [...action.payload];
    },
    setSecciones: (state, action) => {
      state.secciones = [...action.payload];
    },
    setEmisores: (state, action) => {
      state.emisores = { ...action.payload };
    },
    setIntegradores: (state, action) => {
      state.integradores = [...action.payload];
    },
    setCfdis: (state, action) => {
      state.cfdis = [...action.payload];
    },
    setFormasPago: (state, action) => {
      state.formasPago = [...action.payload];
    },
    setMetodosPago: (state, action) => {
      state.metodosPago = [...action.payload];
    },
    setRutas: (state, action) => {
      state.rutas = { ...action.payload }
    },
    setRegimensFical: (state, action) => {
      state.regimensFiscal = [...action.payload]
    },
    setProductoServicios: (state, action) => {
      state.productoServicios = [...action.payload];
    },
    setUnidades: (state, action) => {
      state.unidades = [...action.payload];
    },
    setEstatusPago: (state, action) => {
      state.estatusPago = [...action.payload]
    },
    setEstatusFacturacion: (state, action) => {
      state.estatusFacturacion = [...action.payload]
    },
    setEstatusConciliacion: (state, action) => {
      state.estatusConciliacion = [...action.payload]
    },
    setCatalogoLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setEscenariosContables: (state, action) => {
      state.escenariosContables = [...action.payload]
    },
    setMotivosBaja: (state, action) => {
      state.motivosBaja = [...action.payload]
    },
    setIdentificadores: (state, { payload }) => {
      state.identificadores = [...payload]
    },
    setSectoresCNBV: (state, { payload }) => {
      state.sectoresCNBV = [...payload]
    },
    setEstatusCNBV: (state, { payload }) => {
      state.estatusCNBV = [...payload]
    },
    setCuentasContables: (state, { payload }) => {
      state.cuentasContables = [...payload]
    },
    setTareas: (state, { payload }) => {
      state.tareas = [...payload]
    },
    setArchivosSFTP: (state, { payload }) => {
      state.archivosSFTP = [...payload]
    },
    setCarpetasSFTP: (state, { payload }) => {
      state.carpetasSFTP = [...payload]
    },
    setTiposActor: (state, { payload }) => {
      state.tiposActor = [...payload]
    }
  }
});

export const {
  setGiros,
  setProcesos,
  setDocumentos,
  setBancos,
  setRetenciones,
  setComsiones,
  setIntegraciones,
  setCadenas,
  setTiposLiquidacion,
  setEstados,
  setCiudades,
  setColonias,
  setProductos,
  setEmisores,
  setCfdis,
  setFormasPago,
  setMetodosPago,
  setRegimensFical,
  setUnidades,
  setProductoServicios,
  setEstatusPago,
  setRutas,
  setCatalogoLoaded,
  setEscenariosContables,
  setMotivosBaja,
  setIdentificadores,
  setEstatusFacturacion,
  setEstatusConciliacion,
  setCatProductos,
  setPerfiles,
  setOpciones,
  setSecciones,
  setIntegradores,
  setSectoresCNBV,
  setEstatusCNBV,
  setCuentasContables,
  setTareas,
  setArchivosSFTP,
  setCarpetasSFTP,
  setTiposActor,
} = catalagoSlice.actions;
