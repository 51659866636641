import { Button, Grid, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useModal } from '../../hooks/useModal';
import { ModalBase } from './ModalBase';
import { useEffect, useState } from 'react';

export default function ModalViewTxt() {
  const { isOpenViewDoc, closeModalViewDoc } = useModal();
  const [txtData, setTxtData] = useState("");

  useEffect(() => {
    if (isOpenViewDoc.open) {
      fetch(isOpenViewDoc.path).then((r) => { r.text().then(d => setTxtData(d)) })
    } else {
      setTxtData("")
    }
  }, [isOpenViewDoc])

  return (
    <ModalBase
      open={isOpenViewDoc.open}
      style={{ width: '1100px' }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            height: '90%',
            display: 'block',
            overflowY: 'hidden',
            whiteSpace: 'nonwrap'
          }}
        >
          {txtData !== '' && (
            <Grid item xs={12} style={{
              background: "#f8f8f8",
              borderRadius: 8,
              minHeight: 300,
              maxHeight: 600,
              overflowY: "auto",
              overflowX: "hidden",
              position: "relative"
            }}>
              <Grid style={{ background: "#d1d1d190", padding: "10px 15px", fontWeight: 500, position: "sticky", top: 0, right: 0, backdropFilter: "blur(5px)" }}>{isOpenViewDoc.fileName}</Grid>
              <Grid style={{ padding: "10px 15px" }}>{txtData.split("\n")?.map(row => {
                return (<>
                  {row}
                  <br />
                </>)
              })}</Grid>
            </Grid>
          )}

        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end',
          }}
        >
          <Button onClick={closeModalViewDoc} sx={{ color: '#00609C' }}>
            cerrar
          </Button>
        </div>
      </Box>
    </ModalBase>
  );
};
