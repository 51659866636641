import { API, Storage } from "aws-amplify";
import { cadenasForm } from "../../helpers/forms";

import {
  getDaysOfWeekPayList,
  getFacturaTransferencia,
  getFormatContacts,
  getFormatEscalonados,
} from "../../helpers/getData";
import {
  closeListPermission,
  loading,
  openListPermission,
  openToast,
} from "../ui/uiSlice";
import { setCuentaContable, setPermissions } from "./cadenaSlice";

/**
 * Realiza el alta de los datos generales de la cadena
 * Se modifico el nombre del metodo y se guarda el id de la cadena EGSG 2022-08-26 14:42:41
 * Se modifico la estructura JSON de los params EGSG 2022-09-23 18:05:13
 * @author EGSG 2022-08-26 12:26:50
 * @param form
 * @returns JSON
 */
export const startAltaCadenasDatosGenrales = (form = cadenasForm) => {
  return async (dispatch, getState) => {
    const { idUsuario } = getState().user;
    const params = {
      headers: {},
      response: false, // OPTIONAL
      body: {
        nIdGiro: form.giro,
        nIdEmpleado: idUsuario,
        sNombreComercial: form.nombreComercial,
        sRazonSocial: form.razonSocial,
        sRegimenSocietario: form.regimenSocietario,
        sRFC: form.rfc,
        sActividadEconomica: form.sActividadEconomica,
        sMail              : form.sMail,
        sTelefono          : form.sTelefono,
        // ***** DIRECCION *****
        sCalle: form.calle,
        sNumInterno: form.noInterior, // opcional
        nNumExterno: form.noExterior,
        nCodigoPostal: parseInt(form.codigoPostal),
        nIdColonia: parseInt(form.colonia),
        nIdTipoDireccion: parseInt(form.tipo),
        // **** CONTACTOS ****
        contactos: getFormatContacts(form.contactos),
        // **** BANCARIOS ****
        sBeneficiario: form.beneficiario,
        sClabe: form.clabeInterBanc,
        nIdBanco: form.nIdBanco !== "" ? parseInt(form.banco) : "",
        sRefNumerica: form.refNumerica, // opcional
        sRefAlfaNumerica: form.refAlfaNumerica, // opcional
        // **** OPERATIVOS ****
        nIdRetencion: parseInt(form.retencion),
        nMontoMaximo: form.montoRecepcion,
        nIdIntegracion: parseInt(form.ingragracion),
        nIVAOperacion: form.ivaOperativo, //
        nCostoTransferencia: form.costoTransferencia, //Indicar que este tambien va
        // sTiempoCancelacion: form.tiempoCancel,
        nMontoTranferencia: "",
        sComisionUsuario: form?.comUser ?? "",
        sComisionCadena: form?.comCadena ?? "",
        sComisionCadenaPorcentaje: form?.comCaadenaPercent ?? "",
        nMontoFijo: form?.montoFijo ?? "",
        dMesesGracia: form.nMesesGracia,
        dInicioOperaciones: form.dInicioOperaciones,
        escalonado: getFormatEscalonados(form.escalonadoForms),
        // **** LIQUIDACION ****
        liquidacionCashIn: {
          nIdTipo: parseInt(form.liquidacionCashIn.tipo),
          nDia:
            form.liquidacionCashIn?.tipoSelected?.nPorDia === 1
              ? parseInt(form.liquidacionCashIn.pagoMes)
              : 0, // Si no hay dia mandar 0
          semana:
            form.liquidacionCashIn?.tipoSelected?.nPorDia === 0
              ? getDaysOfWeekPayList(form.liquidacionCashIn)
              : [], // valores del 0-6 || si es por dia mandar vacio []
        },
        liquidacionCashOut: {
          nIdTipo: parseInt(form.liquidacionCashOut.tipo),
          nDia:
            form.liquidacionCashOut?.tipoSelected?.nPorDia === 1
              ? parseInt(form.liquidacionCashOut.pagoMes)
              : 0, // Si no hay dia mandar 0
          semana:
            form.liquidacionCashOut?.tipoSelected?.nPorDia === 0
              ? getDaysOfWeekPayList(form.liquidacionCashOut)
              : [], // valores del 0-6 || si es por dia mandar vacio []
        },
        // **** FACTURA ******

        facturas: getFacturaTransferencia(
          {
            ...form.factura,
            ivaFac: form.ivaFac,
            regimenFiscalFac: form.regimenFiscalFac,
          },
          form.emailsFactura || []
        ),
        // **** DOCUMENTOS ****
        documentos: form.documentos,
        // **** CUENTA CONTABLES ****
        /* cuentasContables: {
          nCuentaIngresos: +form.cuentasContables.nCuentaIngresos.replaceAll(
            "-",
            ""
          ),
          nCuentaCostos: +form.cuentasContables.nCuentaCostos.replaceAll(
            "-",
            ""
          ),
          nCuentaProveedor: +form.cuentasContables.nCuentaProveedor.replaceAll(
            "-",
            ""
          ),
          nCuentaCliente: +form.cuentasContables.nCuentaCliente.replaceAll(
            "-",
            ""
          ),
          nIVATraslado: +form.cuentasContables.nIVATraslado.replaceAll("-", ""),
          nIVAAcreditable: +form.cuentasContables.nIVAAcreditable.replaceAll(
            "-",
            ""
          ),
          nCuentaBancos: +form.cuentasContables.nCuentaBancos.replaceAll(
            "-",
            ""
          ),
        }, */
      },
    };
    let result = { ok: false, msg: "" };
    try {
      dispatch(loading(true));
      const resp = await API.post("cadenas", "/cadenas/alta", params);

      if (resp.nCodigo === 0) {
        const { nCodigo, sMensaje } = resp.data[0];

        result = { ok: nCodigo === 0 ? true : false, msg: sMensaje }; // 0 == Success
      } else {
        result = { ok: false, msg: resp.sMensaje };
      }
    } catch (error) {
      console.log("ocurrio un error" + error);
      dispatch(
        openToast({
          error: true,
          message: error ?? "Error al procesar los datos.",
        })
      );
      result = { ok: false, msg: error ?? "Error al procesar los datos." };
    } finally {
      dispatch(loading(false));
      return result;
    }
  };
};

/**
 * Realiza el consumo de cuentas contables
 * @author EGSG 2022-08-30 2022-09-23 18:06:50
 * @param nTipo
 * @returns JSON
 */

export const startLoadCuentasContablesCadena = (nTipo = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nTipo }, // Cadenas 0; emisores = 1
    };

    let result = { ok: false, msg: "" };
    try {
      dispatch(loading(true));
      const res = await API.get(
        "catalogos",
        "/catalogo/cuentasContables",
        params
      );
        console.log(res)
      dispatch(
        setCuentaContable([
          ...res
        ])
      );
    } catch (error) {
      console.log("ocurrio un error" + error);
      result = { ok: false, msg: error ?? "Error al procesar los datos." };
    } finally {
      dispatch(loading(false));
      return result;
    }
  };
};

/**
 * Obtiene los permisos de la cadena
 * @author CSOSA 2022-09-19 17:34:00
 * @param form
 * @returns JSON
 */

export const getPermissionsData = (
  nIdPermiso,
  nIdCadena,
  nIdEstatus,
  returnFlag = false
) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdPermiso,
        nIdCadena,
        nIdEstatus,
      },
    };

    try {
      dispatch(closeListPermission());
      dispatch(loading(true));
      const resp = await API.get("cadenas", "/cadenas/permisos", params);
      console.log({
        ...resp,
      });
      if (returnFlag) {
        return resp;
      }
      dispatch(
        setPermissions({
          ...resp,
        })
      );
      dispatch(openListPermission());
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

/**
 * Inserta los permisos de la cadena
 * @author CSOSA 2022-09-23 17:34:00
 * @param form
 * @returns JSON
 */

export const insertPermiso = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false, // OPTIONAL
      body: {
        nIdCadena: form.nIdCadena,
        nIdRuta: form.nIdRuta,
        sClaveProducto: form.sClaveProducto,
        nImpComCadena: form.nImpComCadena,
        nPerComCadena: form.nPerComCadena,
        dFEVPermiso: form.dFEVPermiso,
        dFSVPermiso: form.dFSVPermiso,
        nImpComCliente: form.nImpComCliente,
        nPerComCliente: form.nPerComCliente,
        nImpComEspecial: form.nImpComEspecial,
        nPerComEspecial: form.nPerComEspecial,
        nTipoContrato: form.nTipoContrato,
      },
    };
    let result = { ok: false, msg: "" };
    try {
      dispatch(loading(true));
      const resp = await API.post("cadenas", "/cadenas/permiso", params);
      const res = resp?.data?.length ? resp.data[0] : {}
      result = { ok: res?.nCodigo === 0, msg: res?.sMensaje };
      console.log(result);
    } catch (error) {
      console.log("ocurrio un error" + error);
      result = { ok: false, msg: error ?? "Error al procesar los datos." };
    } finally {
      dispatch(loading(false));
      console.log("finally", result);
      return result;
    }
  };
};

/**
 * Actualiza los permisos de la cadena
 * @author CSOSA 2022-09-23 17:34:00
 * @param form
 * @returns JSON
 */
export const updatePermiso = (form, type = "edit") => {
  console.log(form);
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false, // OPTIONAL
      body: {
        nIdPermiso: form.nIdPermiso,
        nIdCadena: form.nIdCadena,
        nIdRuta: form.nIdRuta,
        sClaveProducto: form.sClaveProducto,
        nImpComCadena: form.nImpComCadena,
        nPerComCadena: form.nPerComCadena,
        dFEVPermiso: form.dFEVPermiso,
        dFSVPermiso: form.dFSVPermiso,
        nImpComCliente: form.nImpComCliente,
        nPerComCliente: form.nPerComCliente,
        nImpComEspecial: form.nImpComEspecial,
        nPerComEspecial: form.nPerComEspecial,
        nTipoContrato: form.nTipoContrato,
        nIdEstatus: type === "edit" ? 1 : 0,
      },
    };
    let result = { ok: false, msg: "" };
    try {
      dispatch(loading(true));
      const resp = await API.put("cadenas", "/cadenas/permiso", params);
      const res = resp?.data?.length ? resp.data[0] : {}

      result = { ok: res?.nCodigo === 0, msg: res?.sMensaje };
      console.log(result);
    } catch (error) {
      console.log("ocurrio un error" + error);
      result = { ok: false, msg: error ?? "Error al procesar los datos." };
    } finally {
      dispatch(loading(false));
      console.log("finally", result);
      return result;
    }
  };
};

/**
 * Carga un archivo al bucket de SofiPay
 * @author #RCB 2022-09-29 12:11:43
 * @param file
 * @returns JSON
 */
export const subirArchivoS3 = (file = {}, id = 0, sRFC = "", documentoCatalogo = {}, maxSize = 10) => {
  return async (dispatch) => {
    let result = { ok: false, msg: "" };
    const maxSizeBites = ((maxSize * 1024) * 1024).toFixed(4)
    if (
      !Boolean(file.name) ||
      file.name === "" ||
      !Boolean(file.type) ||
      !Boolean(file.size) ||
      id === 0
    )
      return {
        ok: false,
        msg: "No se recibio un archivo o tiene formato no valido",
      };
    if (file.size > maxSizeBites) return { ok: false, msg: `El archivo supera el tamaño máximo. (${maxSize} MB)` };
    if (sRFC === "") return { ok: false, msg: "Se requiere capturar el RFC" };
    try {
      dispatch(loading(true));
      const resp = await Storage.put(`${sRFC}/${id}/${documentoCatalogo ? documentoCatalogo.sAbrevDoc : file.name}`, file, {
        contentType: file.type,
      });
      result = {
        ok: Boolean(resp.key),
        msg: "Archivo cargado exitosamente",
        key: resp.key,
      };
    } catch (error) {
      console.log("ocurrio un error" + error);
      result = {
        ok: false,
        msg:
          `Error al subir el documento. Error: ${error.message}` ??
          "Error al subir el documento",
      };
    } finally {
      dispatch(loading(false));
      console.log("finally", result);
      return result;
    }
  };
};

/**
 * Obtiene la url firmada de un archivo SofiPay
 * @author #RCB 2022-10-03 12:28:23
 * @param key string
 * @param expireTime OPTIONAL
 * @returns JSON
 */
export const signedURL = (key = "", expireTime = 60) => {
  console.log(key);
  return async (dispatch) => {
    let result = { ok: false, msg: "", url: "" };
    try {
      dispatch(loading(true));
      const url = await Storage.get(key, { expires: expireTime });
      result =
        url !== ""
          ? { ok: true, msg: "Documento encontrado", url: url }
          : { ok: false, msg: "No se encontro el documento" };
      console.log(url);
    } catch (error) {
      result.msg = `Error al obtener el ducumento. Error: ${error.message}`;
      console.log("error al obtener la imagen", error);
    } finally {
      dispatch(loading(false));
      return result;
    }
  };
};

/**
 * Editar una cadena 
 * @author EGSG 2022-11-03 09:38:03
 * @param {*} form
 * @param {*} typeEdit
 * @returns JSON
 */

export const startLoadEditarCadena = (form, typeEdit) =>
  async (dispatch, getState) => {

    const { user, idUsuario } = getState().user;

    const params = {
      headers: {},
      response: false,
      body: {
        token: user.token,
        nIdEmpleado: idUsuario,
        // sTipo: typeEdit,
        ...form,
      }
    }
    let result = { ok: false, msg: '' };

    try {
      dispatch(loading(true));
      const resp = await API.put('cadenas', `/cadenas/${typeEdit}`, params);

      if (resp.nCodigo === 0) {
        const { nCodigo, sMensaje } = resp.data[0];
        result.ok = nCodigo === 0;
        result.msg = sMensaje;

      } else {
        result.ok = false;
        result.msg = resp?.sMensaje ?? 'Error al editar los datos del comisionista';
      }


    } catch (error) {
      console.log(error);
      console.log('ocurrio un error ', + error)
      result.ok = false;
      result.msg = 'Error al editar los datos de la comisionista'

    } finally {
      dispatch(loading(false));
      return result;
    }

  }

/**
 * Obtener productos de una cadena
 * @author CSOSA 2022-11-24 16:10:03
 * @param {*} form
 * @param {*} typeEdit
 * @returns JSON
 */
export const getProducts = (nIdCadena, nDetalle = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        sClaveProducto: 0,
        nIdFlujoImporte: 2,
        nIdCadena,
        nIdEstatus: 1,
        nDetalle
      }
    }
    let productos = []
    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/productos', params);
      productos = resp?.map((producto) => ({
        ...producto,
        value: producto.sClaveProducto + '',
        text: producto.sDescripcion
      }));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return productos
    }

  }
};

/**
 * Obtener todos los productos de los emisores
 * @author CSOSA 2022-12-12 10:10:03
 * @param {*} form
 * @param {*} typeEdit
 * @returns JSON
 */
export const getProductsEmisores = (nDetalle = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        sClaveProducto: 0,
        nIdFlujoImporte: 2,
        nIdEmisor: 0,
        nIdEstatus: 1,
        nDetalle
      }
    }
    let productos = []
    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/productos', params);
      productos = resp?.map((producto) => ({
        ...producto,
        value: producto.sClaveProducto + '',
        text: producto.sDescripcion
      }));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return productos
    }

  }
};

/**
 * Insertar alta o baja de productos en una cadena
 * @author CSOSA 2022-11-24 16:10:03
 * @param {*} form
 * @param {*} typeEdit
 * @returns JSON
 */
export const postProducts = (sProductos = []) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nActor: 2,
        sProductos
      },
    }
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/productos', params);
      console.log(resp)
      return {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
};



export const putEnvioOperaciones = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: form,
    }
    try {
      dispatch(loading(true));
      const resp = await API.put('cadenas', '/cadenas/envio-operaciones', params);

      const data = resp?.data?.length ? resp.data[0] : {}
      return {
        ok: data?.nCodigo === 0,
        msg: data?.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
};
