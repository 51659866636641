import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { sucursalForm } from "../helpers/forms";
import { getUsuariosData, insertUsuario, updateUsuario, updateUsuarioBaja, updateUsuarioEstatus } from "../slices/usuarios/usuariosThunk";
import { startLoadBancos, startLoadCadenas, startLoadColonias, startLoadEmisores, startLoadFiltrosReportes, startLoadPermisosData } from "../slices/catalogo/catalgoThunk";
import { useModal } from "./useModal";
import { regexLatitud, regexLongitud, regexNumExterior, regexNums, regexPassword, regexStrageChart } from "../helpers/regexs";
import { useSearchParams } from "react-router-dom";
import { getSucursalesData, insertSucursal, insertSucursalBaja, updateSucursal, updateSucursalStatus } from "../slices/sucursales/sucursalesThunk";
import { useExportFile } from "./useExportFile";
import moment from "moment";

export const useSucursalesTable = () => {
  const [form, setForm] = useState(sucursalForm);
  const { downloadFile } = useExportFile()
  const { dataTable } = useSelector((state) => state.sucursales);
  const {
    cadenas,
    motivosBaja
  } = useSelector((state) => state.catalogo);
  const dispatch = useDispatch();
  const [showSucursalModal, setShowSucursalModal] = useState({
    open: false,
    type: "add"
  })
  const [showImportSucursales, setShowImportSucursales] = useState(false)
  const { setOpenToast } = useModal();
  const [selectedCadena, setSelectedCadena] = useState(0)

  const [searchParams] = useSearchParams();

  const loadCadenas = async () => {
    if (!cadenas.rows.length) await dispatch(startLoadFiltrosReportes({ nCadenas: 1 }));
  }

  const handleOpenImportSucursales = async () => {
    setShowSucursalModal({
      open: false,
      type: "add"
    })
    await loadCadenas()
    setShowImportSucursales(true)
  }

  const handleCloseImportSucursales = (reload = true) => {
    setShowImportSucursales(false)
    if (reload) getSucursales()
  }

  const onClickAdd = async () => {
    await openSucursalModal("add", sucursalForm)
  }

  const openSucursalModal = async (type, form = {}) => {
    if (type !== "view") await loadCadenas()
    setForm({ ...sucursalForm, ...form })
    setShowSucursalModal({
      open: true,
      type
    })
  }

  const enableEditModal = async (innerForm) => {
    await loadCadenas()
    const nCodigoPostal = form.nCodigoPostal;


    setShowSucursalModal({
      open: true,
      type: "edit"
    })

    if (nCodigoPostal !== '' && nCodigoPostal.length === 5) {
      const resp = await dispatch(startLoadColonias(nCodigoPostal, innerForm.nIdColonia));
      const {
        colonia,
        estado,
        ciudad
      } = resp;
      setForm({ ...sucursalForm, ...innerForm, colonia, estado, ciudad });
    }
  }

  const disableEditModal = () => {
    setShowSucursalModal({
      open: true,
      type: "view"
    })
  }

  const loadInInit = async () => {
    let innerCadena = selectedCadena;
    if (searchParams) {
      innerCadena = parseInt(searchParams.get('nIdCadena') ? searchParams.get('nIdCadena') : innerCadena)
      setSelectedCadena(innerCadena)
      setForm({ ...sucursalForm, nIdCadena: innerCadena })
    }
    await getSucursales(innerCadena);
  };

  const getSucursales = async (nIdCadena = null) => {
    const innerCadena = nIdCadena ?? selectedCadena
    await dispatch(getSucursalesData(0, innerCadena));
  }

  const onChangeInput = async (value) => {
    if (value.target) {
      value = { [value.target.name]: value.target.value }
    }
    if ('nCodigoPostal' in value) {
      const inputValue = value.nCodigoPostal;
      if (inputValue !== '' && !regexNums.test(inputValue)) return;

      setForm({ ...form, ...value });
      if (inputValue !== '' && inputValue.length === 5) {
        const resp = await dispatch(startLoadColonias(inputValue));
        const {
          colonia,
          estado,
          ciudad
        } = resp;

        setForm({ ...form, ...value, colonia, estado, ciudad });
      }

    } else {
      if ('nClaveDireccion' in value) {
        value = {
          ...value,
          nClaveDireccionChanged: true
        }
      }
      setForm({ ...form, ...value });
    }
  };

  const handleCloseModal = () => {
    setShowSucursalModal({
      open: false,
      type: showSucursalModal.type
    })
  }

  const handleSubmit = async () => {
    const innerForm = {
      ...form,
      nClaveDireccionChanged: parseInt(form.nIdColonia) === parseInt(form.colonia) ? form.nClaveDireccion : false,
      nIdColonia: form.colonia,
      nNumExterno: !regexNums.test(form.nNumExterno) && regexNumExterior.test(form.nNumExterno) ? 0 : form.nNumExterno
    }
    const validated = validateData(innerForm);
    if (validated.ok) {
      const resp = await dispatch(showSucursalModal.type === "add" ? insertSucursal(innerForm) : updateSucursal(innerForm));
      if (resp.ok) {
        handleCloseModal()
        await getSucursales();
      }
      setOpenToast(!resp.ok, resp.msg)
    } else {
      setOpenToast(true, validated.msg)
    }
  }

  const handleSubmitChangeEstatus = async (inrForm = false, isBaja = false) => {
    const innerForm = inrForm ? inrForm : form
    const newEstatus = innerForm.nIdEstatus === 1 ? 0 : 1;
    const resp = await dispatch(isBaja ? insertSucursalBaja(innerForm) : updateSucursalStatus(innerForm, newEstatus))
    setOpenToast(!resp.ok, resp.msg);
    if (resp.ok) {
      handleCloseModal()
      await getSucursales();
    }
  }

  const validateData = (form) => {
    if (showSucursalModal.type === "edit") {
      if (form.nClaveDireccionChanged && (!form.nClaveDireccion || !regexNums.test(form.nClaveDireccion)))
        return { ok: false, msg: !form.nClaveDireccion ? "Ingrese la clave de la dirección." : "No se permiten carácteres especiales en la clave de la dirección." }
    }
    if (form.sClaveSucursal === '' || regexStrageChart.test(form.sClaveSucursal))
      return { ok: false, msg: form.sClaveSucursal === '' ? "Ingrese la clave de la sucursal." : "No se permiten carácteres especiales en la clave de la sucursal." }
    if (form.sNombre !== '' && regexStrageChart.test(form.sNombre))
      return { ok: false, msg: "No se permiten carácteres especiales en el nombre." }
    if (!form.nIdCadena)
      return { ok: false, msg: "Seleccione el comisionista." }
    if (form.dFecInicioOperaciones === '')
      return { ok: false, msg: "Ingrese la fecha de inicio de operaciones" }
    if (form.sCalle === '' || regexStrageChart.test(form.sCalle))
      return { ok: false, msg: form.sCalle === '' ? "Ingrese la calle." : "No se permiten carácteres especiales en la calle." }
    if ((!form.nNumExterno && form.nNumExterno !== 0) || regexStrageChart.test(form.nNumExterno))
      return { ok: false, msg: !form.nNumExterno ? "Ingrese el número exterior." : "Número exterior con el formato incorrecto." }
    if (form.sNumInterno !== '' && regexStrageChart.test(form.sNumInterno))
      return { ok: false, msg: "No se permiten carácteres especiales en el número interno." }
    if (!form.nCodigoPostal || !regexNums.test(form.nCodigoPostal))
      return { ok: false, msg: !form.nCodigoPostal ? "Ingrese el código postal." : "Sólo se permiten números en el código postal." }
    if (["", null, undefined].includes(form.nLongitud) || !regexLongitud.test(form.nLongitud))
      return { ok: false, msg: ["", null, undefined].includes(form.nLongitud) ? "Ingrese la longitud." : "La longitud tiene un formato incorrecto." }
    if (["", null, undefined].includes(form.nLatitud) || !regexLatitud.test(form.nLatitud))
      return { ok: false, msg: ["", null, undefined].includes(form.nLatitud) ? "Ingrese la latitud." : "La latitud tiene un formato incorrecto." }

    if (!form.nIdColonia)
      return { ok: false, msg: "Seleccione la colonia." }

    return { ok: true, msg: "Datos validados correctamente" }
  }

  const handleDownload = () => {
    const {
      rows = []
    } = dataTable

    const columns = [
      { field: 'sClaveSucursal', headerName: 'Clave de la sucursal' },
      { field: 'sNombre', headerName: 'Nombre de la sucursal'},
      { field: 'sNombreComercial', headerName: 'Comisionista'},
      { field: 'dFecInicioOperaciones', headerName: 'Fecha de inicio de operaciones', type: "date"},
      { field: 'sCalle', headerName: 'Calle'},
      { field: 'nNumExterno', headerName: 'Núm. exterior'},
      { field: 'sNumInterno', headerName: 'Núm. interior'},
      { field: 'nCodigoPostal', headerName: 'Código postal'},
      { field: 'sEstado', headerName: 'Estado'},
      { field: 'sMunicipio', headerName: 'Municipio'},
      { field: 'nClaveDireccion', headerName: 'Clave de la dirección'},
      { field: 'nLatitud', headerName: 'Latitud'},
      { field: 'nLongitud', headerName: 'Longitud'},
      { field: 'sEstatus', headerName: 'Estado', type: "render", renderFunction: (row) => row.nIdSuspension ? `Suspendido - ID: ${row.nIdSuspension}` : row.sEstatus},
      { field: 'dFecBaja', headerName: 'Fecha de baja', type: 'render', flex: 1, renderFunction: (row) => (row.dFecBaja ? formatDate(row.dFecBaja).format("YYYY-MM-DD") : "")}
    ]

    const dFecha = moment().format("YYYY-MM-DD")

    downloadFile(rows, columns.map(c => ({ ...c, format: c.type })), "excel", `Listado de sucursales (${dFecha})`, "Listado de sucursales", `Fecha: ${dFecha}`)
  }

  const formatDate = (date) => {
    if (date && date[date.length - 1] === "Z") {
      return moment(date.slice(0, -1));
    } else {
      return moment(date);
    }
  };

  return {
    dataTable,
    loadInInit,
    onClickAdd,
    showSucursalModal,
    handleCloseModal,
    form,
    onChangeInput,
    openSucursalModal,
    enableEditModal,
    disableEditModal,
    handleSubmit,
    handleOpenImportSucursales,
    handleCloseImportSucursales,
    showImportSucursales,
    loadCadenas,
    selectedCadena,
    setSelectedCadena,
    handleSubmitChangeEstatus,
    cadenas,
    motivosBaja,
    getSucursales,
    handleDownload,
    formatDate
  };
};
