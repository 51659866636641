import { Alert, Button, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useModal } from '../../hooks/useModal';
import { ModalBase } from './ModalBase';
import { CloudUpload, Download, DvrSharp, FilterAlt, PictureAsPdf, Publish, RemoveRedEye, Summarize } from '@mui/icons-material';
import CustomizedTabs from '../CustomizedTabs';
import { DashboardTable } from '../table/DashboardTable';
import ButtonDropdown from '../ButtonDropdown';
import EnhancedTable from '../table/EnhancedTable';
import { SearchInput } from '../form/SearchInput';
import { useModalArchivoConciliacion } from '../../hooks/useModalArchivoConciliacion';
import { InfoText } from './modalDetail/InfoText';
import moment from 'moment';
import PaginatedTable from '../table/PaginatedTable';
import { useEffect, useState } from 'react';
import { type } from '@testing-library/user-event/dist/type';
import { useExportFile } from '../../hooks/useExportFile';
import { useAuthPage } from '../../hooks/useAuthPage';

export default function ModalMonitoreoERP(props) {

  const colorsTab = ["#053e85", "#00CD68", "#E7B10A", "#e53e3e"]
  const {
    isOpen,
    data,
    selectedTab,
    handleChangeTab,
    runInterfazERP,
    fecha,
    closeModal,
    handleOpenModalBitacora
  } = props
  const [registrosInterfazados, setRegistrosInterfazados] = useState([])
  const [registrosNoInterfazados, setRegistrosNoInterfazados] = useState([])
  const [bitacora, setBitacora] = useState([])

  const { setAllowComponent } = useAuthPage();
  const { downloadFile } = useExportFile()

  useEffect(() => {
    if (isOpen) {
      const {
        sCortes = "[]",
        sEntidades = "[]",
        sBitacora = "[]"
      } = data
      console.log(data)
      const aRegistros = data.nIdEstatusInterfaz === 1 ? JSON.parse(sEntidades) : JSON.parse(sCortes)
      const bitacora = JSON.parse(sBitacora)
      setRegistrosInterfazados(aRegistros.filter(corte => !corte.bError))
      setRegistrosNoInterfazados(aRegistros.filter(corte => corte.bError))
      setBitacora(bitacora)
    } else {
      setRegistrosInterfazados([])
      setRegistrosNoInterfazados([])
      setBitacora([])
    }
  }, [data, isOpen])

  const getColumns = () => {
    return data.nIdTipoInterfaz === 1 ? [
      { field: 'nIdInterfazEntidad', headerName: 'ID interfaz', flex: 1, style: { width: 50 } },
      { field: 'sTipoActor', headerName: 'Tipo entidad', flex: 3 },
      { field: 'sNombreComercial', headerName: 'Entidad', flex: 3 },
      { field: 'dFecInterfaz', headerName: 'Último intento de interfaz', flex: 2, type: "time", style: { width: 100 } },
      { field: 'sResultado', headerName: 'Comentario', flex: 5 }
    ] : [
      { field: 'nIdInterfazCortes', headerName: 'ID interfaz', flex: 1, style: { width: 50 } },
      { field: 'nIdCorte', headerName: 'ID corte', flex: 1, style: { width: 50 } },
      { field: 'dFechaCorte', headerName: 'Fecha corte', flex: 1, type: "date", style: { width: 80 } },
      { field: 'sTipoActor', headerName: 'Tipo entidad', flex: 3 },
      { field: 'sNombreComercial', headerName: 'Entidad', flex: 3 },
      { field: 'sTipoOperacion', headerName: 'Tipo de operación', flex: 3 },
      { field: 'nTotalOperaciones', headerName: 'Total operaciones', flex: 1, type: "number" },
      { field: 'nImporteTotal', headerName: 'Importe total', flex: 1, type: "price" },
      { field: 'dFecInterfaz', headerName: 'Último intento de interfaz', flex: 2, type: "time", style: { width: 100 } },
      { field: 'sResultado', headerName: 'Comentario', flex: 5 }
    ]
  }

  const getRowId = () => {
    return data.nIdTipoInterfaz === 1 ? 'nIdInterfazEntidad' : "nIdInterfazCortes"
  }

  const exportData = (format) => {

    const dFecha = moment().format("YYYY-MM-DD")
    const sNombreBitacora = getDownloableName()
    const nId = getRowId()

    downloadFile(
      getDownloableData().sort((a, b) => parseFloat(a[nId]) - parseFloat(b[nId])),
      getColumns().map(c => ({ ...c, format: c.type })),
      format,
      `Interfaz de ${data?.sTipoInterfaz} (${sNombreBitacora}, ${dFecha})`,
      `Interfaz de ${data?.sTipoInterfaz} (${sNombreBitacora})`,
      `Fecha: ${dFecha}`)
  }

  const getDownloableData = () => {
    switch (selectedTab) {
      case "interfazadas":
        return registrosInterfazados
      case "noInterfazadas":
        return registrosNoInterfazados
      default:
        return [];
    }
  }

  const getDownloableName = () => {
    switch (selectedTab) {
      case "interfazadas":
        return "Registros interfazados"
      case "noInterfazadas":
        return "Registros no interfazados"
      default:
        return [];
    }
  }

  const getNextTime = () => {
    const horasInterfaz = ["08:00:00", "08:30:00", "09:00:00", "09:30:00", "10:00:00", "10:30:00", "11:00:00", "11:30:00"]
    const actualTime = convertirAHorasEnMinutos(moment().format("HH:mm:ss"));

    if (moment(fecha).isBefore(moment(), "date") || actualTime > convertirAHorasEnMinutos(horasInterfaz[horasInterfaz.length - 1])) {
      return "Dia siguiente"
    } else {
      let proximaHora = null
      horasInterfaz.map((hora, key) => {
        if (!proximaHora && actualTime < convertirAHorasEnMinutos(hora)) {
          console.log(actualTime, moment(hora, "HH:mm:ss"))
          proximaHora = horasInterfaz[key]
          console.log(proximaHora)
        }
      })
      return proximaHora ?? horasInterfaz[0]
    }

  }

  const convertirAHorasEnMinutos = (hora) => {
    const [horas, minutos] = hora.split(':').map(Number);
    return horas * 60 + minutos;
  };

  const showScreen = () => {
    switch (selectedTab) {
      case "resumen":
        return <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Datos de la interfaz</Typography>
          </Grid>
          <InfoText title="Estatus actual de la interfaz" text={data?.sEstatusInterfaz} xs={12} sm={4} />
          <InfoText title="Último comentario" text={data?.sComentario} xs={12} sm={4} />
          <InfoText title="Última hora de interfaz" text={data?.dHoraInterfaz} xs={12} sm={4} />
          <InfoText title="Registros disponibles" text={data?.nRegistrosDisponibles} xs={12} sm={4} />
          <InfoText title="Registros interfazados" text={data?.nRegistrosInterfazados} xs={12} sm={4} />
          <InfoText title="Próxima hora de interfaz" text={getNextTime(data?.dHoraInterfaz)} xs={12} sm={4} />

          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Bitácora</Typography>
          </Grid>
          <Grid item xs={12} sx={{ maxHeight: 300, overflow: "auto" }}>
            {
              !bitacora.length ?
                <Alert severity="info">Sin registros</Alert>
                :
                <DashboardTable
                  rows={bitacora.sort(function (a, b) {
                    return a.dHoraInterfaz.localeCompare(b.dHoraInterfaz);
                  })}
                  tableName={"interfazResumeTable"}
                  columns={[
                    {
                      headerName: "Hora",
                      field: "dHoraInterfaz",
                      type: "time",
                    },
                    {
                      headerName: "Estatus",
                      field: "sEstatusInterfaz",
                      type: "render",
                      renderFunction: (row) => (
                        <Grid style={{ whiteSpace: "break-spaces" }}>
                          <p style={{ display: "inline-block", color: colorsTab[row.nIdEstatusInterfaz], background: `${colorsTab[row.nIdEstatusInterfaz]}15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0, fontSize: 13 }} >{row.sEstatusInterfaz}</p>
                        </Grid>
                      )
                    },
                    {
                      headerName: "Comentario",
                      field: "sComentario",
                    },
                    {
                      headerName: "Registros disponibles",
                      field: "nRegistrosDisponibles",
                      type: "number",
                    },
                    {
                      headerName: "Registros interfazados",
                      field: "nRegistrosInterfazados",
                      type: "number",
                    },
                    {
                      headerName: "",
                      field: "",
                      type: "render",
                      renderFunction: (row) => (
                        <IconButton onClick={() => handleOpenModalBitacora(row)} size='small'><RemoveRedEye /></IconButton>
                      )
                    },
                  ]}
                  disableFooter
                />
            }

          </Grid>
        </Grid>
      case "interfazadas":
        return <Grid item xs={12}>
          <EnhancedTable
            isModalTable
            table={{
              columns: getColumns(),
              rows: registrosInterfazados
            }}
            rowId={getRowId()}
            disablePathParameters
            disableButtons
          />
        </Grid>
      case "noInterfazadas":
        return <Grid item xs={12}>
          <EnhancedTable
            isModalTable
            table={{
              columns: getColumns(),
              rows: registrosNoInterfazados
            }}
            rowId={getRowId()}
            disablePathParameters
            disableButtons
          />
        </Grid>
    }
  }

  const formatDate = (date) => {
    if (date && date[date.length - 1] === "Z") {
      return date.slice(0, -1);
    } else {
      return date;
    }
  };


  return (
    <ModalBase
      open={isOpen}
      style={{ width: selectedTab !== "resumen" ? '1200px' : '800px' }}
      disableLoading
    >
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TitleModal title={`${data?.sTipoInterfaz} (${fecha})`} subtitle={"Detalles interfaz"} extraButton={selectedTab !== "resumen" ? <>
          <ButtonDropdown
            startIcon={<Download />}
            label="Exportar"
            options={[
              { startIcon: <PictureAsPdf />, label: "PDF", action: () => exportData("pdf") },
              { startIcon: <Summarize />, label: "Excel", action: () => exportData("excel") },
            ]}
          />
        </> : <>
          {(setAllowComponent("interfazarRegistros") && moment().isSame(moment(fecha), "date")) && <Button
            variant="contained"
            color="primary"
            onClick={runInterfazERP}
            startIcon={<CloudUpload />}
          >
            Interfazar registros
          </Button>}
        </>} />
        <Grid container sx={{ mt: 1, mb: 2, maxHeight: 800, overflow: 'auto', width: "100%" }}>
          <Grid item xs={12} container justifyContent={"space-between"} paddingLeft={1}>
            <Grid item xs={12}>
              <CustomizedTabs
                tabs={[
                  { label: "Resumen", value: "resumen" },
                  { label: "Interfazadas", value: "interfazadas", count: registrosInterfazados.length },
                  { label: "No interfazadas", value: "noInterfazadas", count: registrosNoInterfazados.length },
                ]}
                value={selectedTab}
                onChange={handleChangeTab}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container padding={2} sx={{ background: "#f9f9f9", borderRadius: 2 }}>
              {showScreen()}
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end',
          }}
        >
          <Button onClick={closeModal} sx={{ color: '#00609C' }}>
            cerrar
          </Button>
        </div>
      </Box>
    </ModalBase>
  );
};


const TitleModal = ({ title, subtitle, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <DvrSharp fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};
