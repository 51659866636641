import { localStorageKeys } from "./types";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export const nIdTipoLiquidacion24Hrs = 4;

export const liquidacion = {
  tipo: '1',
  tipoSelected: null,
  pagoMes: '',
  lunes: '',
  martes: '',
  miercoles: '',
  jueves: '',
  viernes: '',
  sabado: '',
  domingo: '',
  nMontoBase: 0,
  nPerTransaccion: 0
};

const factura = {
  cfdi: '',
  claveProducto: '',
  claveUnidad: '',
  periocidad: '',
  diasLiquidacion: 0,
  formaPago: '',
  metodoPago: '',
  emails: [],
};

export const cadenasForm = {
  nombreComercial: '',
  razonSocial: '',
  rfc: '',
  integrador: '',
  nPorcentajeComision: '',
  contactos: [],
  // pais             : '2',
  // zonaHorario      : '',
  giro: '',
  regimenSocietario: '',
  regimenFiscal: '',
  nIdIdentificador: 0,
  nIdIntegrador: 0,
  sMail: '',
  sTelefono: '',

  // ****** DIRECCION ******
  tipo: '',
  calle: '',
  noExterior: '',
  noInterior: '',
  codigoPostal: '',
  colonia: '',
  ciudad: '',
  estado: '',
  // ****** DATOS BANCOS ******
  clabeInterBanc: '',
  banco: '',
  refNumerica: '',
  refAlfaNumerica: '',
  beneficiario: '',
  // ****** DATOS OPERATIVOS ******
  pagoComision: '',
  retencion: '',
  ivaOperativo: '1',
  ivaOperativoVal: 0.16,
  costoTransferencia: '0',
  montoRecepcion: 0,
  ingragracion: '',
  isEscaloando: false,
  escalonadoForms: [],
  // ****** DOCUMENTOS ******
  documentos: {},
  // ****** LIQUIDACION ******
  liquidacionCashIn: liquidacion,
  liquidacionCashOut: liquidacion,
  isActiveCashIn: false,
  isActiveCashOut: false,
  liquidacion,
  // ********* FACTURAS ******
  factura: {
    ...factura,
    periocidad: '3',
    claveProducto: '', //80141628
    claveUnidad: '', //ACT
    cfdi: 'G03',
    formaPago: '99',
    metodoPago: 'PUE'
  },
  ivaFac: '',
  regimenFiscalFac: '601',
  // ***** EFR ****
  isEscCantidad: true,
  montoBase: '0',
  facturacionMinima: '0',
  sActividadEconomica: '',
  nMesesGracia: moment().endOf('month').format('YYYY-MM-DD').toString(),
  dInicioOperaciones: moment().format('YYYY-MM-DD'),
  isActiveMGracia: false,
  isIndirecto: false,
  sMensajeTicket: ''
}

export const escalonadoForm = {
  id: uuidv4(),
  montoMinimo: '0.01',
  montoMaximo: '',
  comisionMontoFijo: '',
  comisionPorcentaj: '',
  isActiveDelete: false,
  disableInputs: false,
  isFirst: true,
};

export const escCantidadForm = {
  id: uuidv4(),
  cantidadMinimo: '1',
  cantidadMaxima: '',
  comisionMontoFijo: '0',
  isActiveDelete: false,
  disableInputs: false,
  isFirst: true,
}

export const reportsForm = {
  title: '',
  type: 0,
  productType: 2,
  nIdEmisor: 0,
  nIdIntegrador: 0,
  nIdCadena: 0,
  sClaveProducto: 0,
  nNumTransaccion: "",
  nIdEstatusPago: 0,
  nTipoPago: 2,
  nPeriodo: 0,
  nIdTipoPago: 0,
  dMonth: 0,
  dYear: parseInt(moment().format('YYYY')),
  dFecInicial: moment().format('YYYY-MM-DD'),
  dFecFinal: moment().format('YYYY-MM-DD'),
  dFechaPago: moment().format('YYYY-MM-DD'),
  nDetalle: false,
  sArchivo: '',
  nPersonalizarAgrupacion: false,
  nTipoAgrupacion: 0
};

export const polizasForm = {
  sFolio: '',
  nIdEscenario: 0,
  nIdActor: 0,
  dFecInicial: moment().format('YYYY-MM-DD'),
  dFecFinal: moment().format('YYYY-MM-DD'),
  nIdEstatus: 1
};

export const registrosBancariosForm = {
  sConcepto: '',
  sReferencia: '',
  type: 1, //1. emisor, 2.cadena
  nIdEmisor: 0,
  nIdCadena: 0,
  nTipo: 2,
  dFecInicial: moment().format('YYYY-MM-DD'),
  dFecFinal: moment().format('YYYY-MM-DD'),
  nIdEstatus: 1
};

export const importForm = {
  dateFormat: 'DDMMYYYY',
  sConcepto: 0,
  sTipo: "+",
  sDescripcion: "Descripción",
  setInitialDatetime: 0,
  dInicial: moment().format('YYYY-MM-DD HH:mm'),
};

export const rutaForm = {
  modalType: 'add',
  nIdEmisor: 0,
  nIdRuta: 0,
  nImpComProducto: 0,
  nImpMaxRuta: 0,
  nImpMinRuta: 0,
  nPerComProducto: 0,
  sClaveProducto: '',
  innersClaveProducto: '',
  sDescripcion: '',
  addPermissionsRoute: '',
  dFecComisionistas: moment().format('YYYY-MM-DD'),
  dFEVRuta: moment().format('YYYY-MM-DD'),
  dFSVRuta: moment().format('YYYY-MM-DD'),
};

export const usuarioForm = {
  nIdUsuario: 0,
  sProcesos: [],
  sNombre: "",
  sAPaterno: "",
  sAMaterno: "",
  sCorreo: "",
  sUsuario: "",
  nIdPerfil: "",
  sPassword: "",
  sPermisos: [],
  nPersonalizado: 0,
  nIdPerfilPlantilla: 0,
  savePerfil: false,
  sNombrePerfil: "",
  sDescripcionPerfil: "",
  isEmailChanged: false
}

export const usuarioPerfilForm = {
  nIdPerfil: 0,
  sNombre: "",
  sDescripcion: "",
  sPermisos: [],
  isPermisosChanged: false
}

export const suspensionForm = {
  nIdCadena: 0,
  nTodosProductos: true,
  nTodasSucursales: true,
  sProductos: [],
  sSucursales: []
}

export const usuarioExternoForm = {
  nIdUsuario: 0,
  sNombre: "",
  sAPaterno: "",
  sAMaterno: "",
  sCorreo: "",
  sUsuario: "",
  sTelefono: "",
  sArea: "",
  sPuesto: "",
  sComentario: "",
  nIdCadena: 0,
  isEmailChanged: false
}

export const sucursalForm = {
  nIdSucursal: 0,
  sClaveSucursal: "",
  sNombre: "",
  nIdCadena: 0,
  dFecInicioOperaciones: moment().format('YYYY-MM-DD'),
  sCalle: "",
  nNumExterno: "",
  sNumInterno: "",
  nCodigoPostal: "",
  estado: "",
  ciudad: "",
  colonia: "",
  nLongitud: "",
  nLatitud: "",
  nIdColonia: 0,
  sEstado: "",
  sMunicipio: "",
  sNombreColonia: "",
  nClaveDireccion: "",
  nClaveDireccionChanged: false
}

export const permissionForm = {
  modalType: 'add',
  nIdCadena: 0,
  nIdPermiso: 0,
  nIdRuta: 0,
  innernIdRuta: "",
  nImpComCadena: 0,
  nPerComCadena: 0,
  nImpComCliente: 0,
  nPerComCliente: 0,
  nImpComEspecial: 0,
  nPerComEspecial: 0,
  nTipoContrato: 0,
  dFEVPermiso: moment().format('YYYY-MM-DD'),
  dFSVPermiso: moment().format('YYYY-MM-DD'),
};

export const facturasForm = {
  nActor: 0,
  nIdEmisor: 0,
  nIdCadena: 0,
  nIdEstatusFacturacion: 0,
};

export const importSucursalesForm = {
  nIdCadena: 0,
  sMotivoBaja: "",
  dFecInicioOperaciones: moment().format('YYYY-MM-DD'),
};

export const autorizacionForm = {
  nActor: 0,
  nIdEmisor: 0,
  nIdCadena: 0,
};

export const conciliacionForm = {
  nActor: 0,
  nIdEmisor: 0,
  nIdCadena: 0,
  sArchivo: "",
  nIdEstatusConciliacion: 0,
  nIdArchivo: 0,
  innerCadena: 0,
  innerEmisor: 0,
  innerFecInicial: null,
  innerFecFinal: null,
  dFechaConciliacion: moment().format('YYYY-MM-DD')
};

export const emailFacutra = {
  id: uuidv4(),
  email: '',
  isActiveDelete: false,
  isAciveDisable: false
}

export const emailsFactura = {
  comision: [emailFacutra],
  transferencia: [{ ...emailFacutra, id: uuidv4() }],
  minimo: [{ ...emailFacutra, id: uuidv4() }],
  cadena: [{ ...emailFacutra, id: uuidv4() }]
}

export const facturacionForm = {
  iva: '',
  regimenFiscal: '',
  comision: factura,
  transferencia: factura,
  minimo: factura
}

export const getDatosFacturacion = () => {
  const dataFact = localStorage.getItem(localStorageKeys.factura);
  return dataFact ? JSON.parse(dataFact) : null;
}

export const cuentaContable = {
  nCuentaIngresos: '',
  nCuentaCostos: '',
  nCuentaProveedor: '',
  nCuentaBancos: '',
  nCuentaCliente: '',
  nIVATraslado: '',
  nIVAAcreditable: '',
}

export const contablesCash = {
  cashIn: cuentaContable,
  cashOut: cuentaContable
}
const direccionDefaultEFR = {
  sCalle: '',
  nIdTipo: 0,
  nIdColonia: 0,
  nIdEntidad: 0,
  nNumExterno: 0,
  sNumInterno: '',
  nIdDireccion: 0,
  nIdMunicipio: 0,
  sDescripcion: '',
  nCodigoPostal: '',
  sNombreColonia: '',
  sNombreEntidad: '',
  sNombreMunicipio: '',
  sTipoDireccion: '',
};
export const cuentasDefault = {
  nCuentaBanco: '',
  nCuentaCostos: '',
  nCuentaCliente: '',
  nCuentaIngresos: '',
  nCuentaProveedor: '',
  nCuentaIVATraslado: '',
  nCuentaIVAAcreditable: ''
}



export const initalStateCadenaDetail = {
  documentos: {},
  nIdCadena: 0,
  sNombreComercial: '',
  sRazonSocial: '',
  sRegimenSocietario: '',
  sRFC: '',
  sGiro: '',
  nIdGiro: '',
  nIdEstatusCadena: 0,
  nIVA: 0,
  nIdTipoLiquidacion: 0,
  nDiaPago: 0,
  nIdIVA: 0,
  nCostoTransferencia: 0,
  nIdIntegracion: 0,
  nIdConfiguracion: 0,
  sIntegracion: '',
  nIdRetencion: 0,
  sRetencion: '',
  nMontoMaximo: 0,
  nIdDireccion: 0,
  sTipoDireccion: '',
  nIdTipoDireccion: 0,
  nIdColonia: 0,
  sNombreColonia: '',
  nIdEntidad: 0,
  sNombreEntidad: '',
  nIdMunicipio: 0,
  sNombreMunicipio: '',
  sCalle: '',
  nNumExterno: 0,
  sNumInterno: '',
  nCodigoPostal: '',
  nIdCuentaBanco: 0,
  sBeneficiario: '',
  sClabe: '',
  sRefNumerica: '',
  sRefAlfanumerica: '',
  sNombreBanco: '',
  nIdBanco: 0,
  sUsoCFDI: '',
  sClaveProductoServicio: '',
  sUnidad: '',
  sFormaPago: '',
  sMetodoPago: '',
  sCorreoDestino: '',
  sRegimenFiscal: 0,
  nPeriodoFacturacion: 0,
  nDiaFacturacionSemanal: 0,
  nCuentaIngresos: '',
  nCuentaCostos: '',
  nCuentaProveedor: '',
  nCuentaBanco: '',
  nCuentaCliente: '',
  nCuentaIVATraslado: '',
  nCuentaIVAAcreditable: '',
  nIdConfConexion: 0,
  sHost: '',
  nPort: '',
  sUser: '',
  sPassword: '',
  sFolder: '',
  sNombreRegimenFiscal: '',
  sNombreUsoCFDI: '',
  sNombreFormaPago: '',
  sNombreMetodoPago: '',
  sNombreClaveProductoServicio: '',
  sNombreUnidad: '',
  sNombrePeriodoFacturacion: '',
  oDocumentos: [],
  oContactos: [],
  oLiquidacionCashIn: [],
  oLiquidacionCashOut: [],
  oEscalonadas: [],
  oCuentasContables: [],
  liquidacionCashIn: liquidacion,
  liquidacionCashOut: liquidacion,
  isActiveCashIn: false,
  isActiveCashOut: false,
  isActiveEscalonado: false,
  oDocsUpdated: [],
  nIdFacturaCadena: 0,
  sActividadEconomica: '',
};
export const initalStateEmisorDetail = {
  documentos: {},
  nIdEmisor: 0,
  sRazonSocial: '',
  sNombreComercial: '',
  sRegimenSocietario: '',
  sActividadEconomica: '',
  sRegimenFiscal: '',
  sRFC: '',
  nIVA: 0.16,
  nIdIVA: '1',
  nIdConfConexion: 0,
  sHost: '',
  nPort: '',
  sUser: '',
  sPassword: '',
  sFolder: '',
  oDirecciones: [],
  oContactos: [],
  nIdCuentaBanco: 0,
  sBeneficiario: '',
  sClabe: '',
  sRefNumerica: '',
  sRefAlfanumerica: '',
  sNombreBanco: '',
  nIdBanco: 0,
  nIdRetencion: 0,
  sRetencion: '',
  sIntegracion: '',
  nIdIntegracion: '',
  nFacturacionMinima: '0',
  dPeriodoGracia: '',
  nCostoTransferencia: '0',
  oEslonadosOperacion: [],
  oEscalonadosTicket: [],
  oLiquidacionCashIn: [],
  oLiquidacionCashOut: [],
  oDocumentos: [],
  oFacturas: [],
  oCuentasContables: [],
  oCuentasContablesCashIn: [cuentasDefault],
  oCuentasContablesCashOut: [cuentasDefault],
  oDireccion: direccionDefaultEFR,
  isActiveEscOperacion: false,
  isActiveEscTicket: false,
  isActiveCashIn: false,
  isActiveCashOut: false,
  liquidacionCashIn: liquidacion,
  liquidacionCashOut: liquidacion,
  oDocsUpdated: []
};

export const facturaSelectedValues = {
  sUnidad: '',
  sUsoCFDI: '',
  sFormaPago: '',
  sMetodoPago: '',
  nIdTipoFactura: 0,
  sCorreoDestino: '',
  sRegimenFiscal: '',
  nComisionMinima: 0,
  sNombreTipoFactura: '',
  nDiasLiquidaFactura: 0,
  nPeriodoFacturacion: 0,
  sClaveProductoServicio: '',
  sNombreRegimenFiscal: '',
  sNombreUsoCFDI: '',
  sNombreFormaPago: '',
  sNombreMetodoPago: '',
  sNombreClaveProductoServicio: '',
  sNombreUnidad: '',
  sNombrePeriodoFacturacion: ''
};

export const cuentaContableDoomiCadenas = { // cuentasContables
  nCuentaBancos: '32132132132', //
  nCuentaCliente: '65465465465',
  nCuentaProveedor: '',
  nCuentaIngresos: '4654654', // 4-6-54-654
  nCuentaCostos: '4654654',// No aplica para emisionres
  nIVATraslado: '',
  nIVAAcreditable: ''                // NO apilica para emisores
};

export const cuentaContableDoomiEmisores = {
  nCuentaIngresos: '4654654',
  nCuentaProveedor: '132132132',
  nCuentaBancos: '32132132132',
  nCuentaCliente: '65465465465',
  nIVATraslado: '21321321312'
}

export const moveScroll = (id) => {
  document
    .getElementById(id)
    ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
};

export const nIdEstatusForm = {
  inactivo: 0,
  activo: 1,
  baja: 2,
  pendiente: 3
}

export const envioEmailForm = {
  nPeriodo: 0,
  sDestinatariosNegocio: [],
  sDestinatariosInternos: []
}

export const catalogoBancoForm = {
  nIdBanco: "",
  sNombre: "",
  sDescripcion: "",
  sClave: "",
  bCorresponsalia: false,
}

export const catalogoGiroForm = {
  nIdGiro: 0,
  sDescripcion: "",
}

export const catalogoIdentificadorForm = {
  nIdIdentificador: 0,
  nIdSector: 0,
  sClave: "",
  sDescripcion: "",
  sIdentificador: "",
  nIdEstatusCNBV: 0,
}

export const catalogoSectorForm = {
  nIdSector: "",
  sDescripcion: "",
}

export const catalogoDocumentosForm = {
  nIdDocumento: 0,
  sNombreDocumento: '',
  sAbrevDoc: '',
  nTipo: 0
}

export const catalogoMotivosBajaForm = {
  nIdMotivoBaja: 0,
  sDescripcion: '',
  nIdCatComisionistaBaja: 0,
  nIdCatComisionistaBajaIFPE: ''
}

export const catalogoProcesoForm = {
  nIdProceso: 0,
  sDescripcion: '',
  sTareas: [],
  nTipo: 0,
  nRequerido: 0
}

export const opsReferenciaForm = {
  sReferencia: "",
  sTicket: '',
  sAutorizacion: "",
  dFecFinal: moment().format("YYYY-MM-DD"),
  dFecInicial: moment().format("YYYY-MM-DD")
}

export const conexionSFTPForm = {
  nIdCfgConexion: 0,
  sNombreConexion: "",
  sHost: "",
  nPort: "",
  sUser: "",
  sPassword: "",
  sComentario: ""
}

export const archivoSFTPForm = {
  nIdArchivoSFTP: 0,
  nIdEntidadParticipante: 0,
  sNombreArchivo: "",
  bObligado: 0,
  sComentario: "",
  bGeneradoPorSofiPay: 0,
  nIdTarea: 0,
  nIdCarpetaSFTP: "",
  sCarpeta: "",
  bLectura: 0,
  bEscritura: 0
}


export const conexionSPForm = {
  nIdCfgConexion: 0,
  sNombreConexion: "",
  sHost: "",
  nPort: "",
  sUser: "",
  sPassword: "",
  sComentario: "",
  showPassword: false,
  sInnerPassword: "",
  hasDecrypted: false,
  isPasswordChanged: false
}

export const tipoActorForm = {
  nIdTipoActor: 0,
  sNombre: "",
  sDescripcion: "",
}