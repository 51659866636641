import { Table } from '../components/table/Table';
import { useCadenasTable } from '../hooks/useCadenasTable';
import { useEffect, useState } from 'react';
import { createTheme, IconButton, ThemeProvider, Tooltip } from '@mui/material';
import { useAuthPage } from '../hooks/useAuthPage';
import { ModalListPermission } from '../components/modal/ModalListPermission';
import { ModalPermission } from '../components/modal/ModalPermission';
//configuracion de conciliacion
import { ConciliacionModal } from '../components/ConciliacionModal';
import { useModalConciliacion } from '../hooks/useModalConciliacion';
import { ModalConfirmacion } from '../components/modal/ModalConfirmacion';
import { useModal } from '../hooks/useModal';
import { ModalDeleteEntity } from '../components/modal/ModalDeleteEntity';
import { ModalProducts } from '../components/modal/ModalProducts';
import EnhancedTable from '../components/table/EnhancedTable';
import VisibilityIcon from "@mui/icons-material/Visibility";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { startLoadMotivosBaja } from '../slices/catalogo/catalgoThunk';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Hub, ReceiptLong } from '@mui/icons-material';
import { ModalEnvioOperaciones } from '../components/modal/ModalEnvioOperaciones';
import { ModalConexionesSFTP } from '../components/modal/ModalConexionesSFTP';

const iconTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#00609C',
      dark: '#053e85'
    },
    neutral: {
      main: '#00CD68',
      light: '#053e85',
      dark: '#053e85',
      contrastText: '#fff'
    }
  }
});

const colorsTab = ["#E7B10A", "#00CD68", "#e53e3e", "#053e85", "#6200EE"]

export const VerCadenasPage = () => {

  const dispatch = useDispatch();
  const {
    loadInInit,
    cadenas,
    onClickAdd,
    emisores,
    rutas,
    getPermissions,
    onChange,
    form,
    openModalPermission,
    onChangeInput,
    deletePermission,
    savePermission,
    onChangeForm,
    deleteCadena,
    motivosBaja,
    openProductsModal,
    cadenaProductos,
    submitProducts,
    productos,
    productosEmisores,
    productosCadena,
    openModalEnvioOperaciones
  } = useCadenasTable();
  const { openModalConciliacion } = useModalConciliacion();
  const { openModalConfirmacion, isTableLoading, openModalConexionesSFTP } = useModal();
  const { setAllowComponent } = useAuthPage();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedCadena, setSelectedCadena] = useState({});
  const nextNotificationDate = moment().add(1, 'M').startOf('month')

  const navigate = useNavigate();
  const estatusCadena = {
    inactivo: 0,
    activo: 1,
    baja: 2,
    pendiente: 3
  }
  const triggerDeleteDialog = async (nIdCadena) => {
    let item = cadenas.rows.find((cadena) => cadena.nIdCadena === nIdCadena);
    if (item) {
      setSelectedCadena(item);
      if (!motivosBaja.length) await dispatch(startLoadMotivosBaja());
      setShowDeleteDialog(true);
    }
  };

  useEffect(() => {
    loadInInit();
    // eslint-disable-next-line
  }, []);

  const formatDate = (date) => {
    if (date && date[date.length - 1] === "Z") {
      return moment(date.slice(0, -1));
    } else {
      return moment(date);
    }
  };

  return (
    <ThemeProvider theme={iconTheme}>
      <ModalDeleteEntity
        entidad={selectedCadena}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        motivosBaja={motivosBaja}
        deleteFunction={deleteCadena}
      />
      <ModalEnvioOperaciones />
      <ConciliacionModal />
      <ModalConexionesSFTP />
      <ModalListPermission
        openModalPermission={openModalPermission}
        deletePermission={deletePermission}
        form={form}
        cadenas={cadenas.rows}
      />
      <ModalPermission
        onChange={onChange}
        form={form}
        onChangeInput={onChangeInput}
        emisores={emisores.rows}
        rutas={rutas.rows}
        cadenas={cadenas.rows}
        onSave={savePermission}
        onChangeForm={onChangeForm}
        productosEmisores={productosEmisores}
        productosCadena={productosCadena}
      />
      <ModalProducts
        form={cadenaProductos}
        entityArray={cadenas.rows}
        emisores={emisores.rows}
        productos={productos}
        onSubmit={submitProducts}
        productosEmisores={productosEmisores}
        openProductsModal={openProductsModal}
        isCadena
      />
      <ModalConfirmacion />
      <EnhancedTable
        table={{
          ...cadenas,
          columns: [
            ...cadenas.columns,
            {
              field: 'sEstatusCadena', headerName: 'Estado', type: 'render', flex: 1, renderFunction: (row) => (
                  <p style={{ display: "inline-block", color: colorsTab[row.nIdSuspension ? 4 : row.nIdEstatusCadena], background: `${colorsTab[row.nIdSuspension ? 4 : row.nIdEstatusCadena]}15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0, fontSize: 13 }} >{row.nIdSuspension ? `Suspendido - ID: ${row.nIdSuspension}` : row.sEstatusCadena}</p>
              )
            },
            {
              field: 'dFecNotificacion', headerName: 'Notificación', type: 'render', flex: 1, disableTooltip: true, renderFunction: (row) => (
                <Tooltip
                  title={row.dFecNotificacion === null ?
                    `Notificación en: ${nextNotificationDate.format("YYYY-MM-DD")}` :
                    <span>{formatDate(row.dFecNotificacion).format("YYYY-MM-DD HH:mm:ss")}<br></br> {row.nTipoNotificacion === 1 ? "Alta" : "Baja"} de producto {row.sClaveProductoNotificacion} notificada a {row.sNombreEmisorNotificacion}</span>
                  }>
                  <p>
                    {row.dFecNotificacion === null ?
                      <>Próxima notificación <br></br>{nextNotificationDate.locale("es").fromNow()}</> :
                      <>Última notificación <br></br>{formatDate(row.dFecNotificacion).locale("es").fromNow()}</>}
                  </p>
                </Tooltip>
              )
            },
            {
              field: 'dFecBaja', headerName: 'Fecha de baja', type: 'render', flex: 1, renderFunction: (row) => (row.dFecBaja ? formatDate(row.dFecBaja).format("YYYY-MM-DD") : "")
            },
          ]
        }}
        loading={isTableLoading}
        buttons={[
          {
            label: "Ver",
            icon: <VisibilityIcon fontSize={"small"} />,
            onClick: (id) => openModalConfirmacion(id),
            showButton: setAllowComponent('verCadenas')
          },
          {
            label: "Productos",
            icon: <WorkspacesIcon fontSize={"small"} />,
            onClick: (id) => openProductsModal(id),
            disabled: (id,row) => row.nIdEstatusCadena !== estatusCadena.activo,
            showButton: setAllowComponent('verProductos')
          },
          {
            label: "Permisos",
            icon: <FactCheckIcon fontSize={"small"} />,
            onClick: (id) => getPermissions(id),
            disabled: (id,row) => row.nIdEstatusCadena !== estatusCadena.activo,
            showButton: setAllowComponent('verPermisos')
          },
          {
            label: "Sucursales",
            icon: <StorefrontRoundedIcon fontSize={"small"} />,
            onClick: (id) => navigate(`/sucursales?nIdCadena=${id}`),
            showButton: setAllowComponent('verSucursales')
          },
          {
            label: "Conexiones SFTP",
            icon: <Hub fontSize={"small"} />,
            onClick: (id,row) => openModalConexionesSFTP({nIdCadena: id}),
            showButton: setAllowComponent("modalConexionesSFTP") || true
          },
          {
            label: "Eliminar",
            icon: <DeleteIcon fontSize={"small"} />,
            onClick: async (id) => await triggerDeleteDialog(id),
            disabled: (id,row) => [estatusCadena.pendiente, estatusCadena.baja].includes(row.nIdEstatusCadena),
            showButton: setAllowComponent('eliminarCadenas')
          }
        ]}
        add={setAllowComponent('agregarCadenas')}
        onAddFunction={onClickAdd}
        rowId={'nIdCadena'}
      />
    </ThemeProvider>
  );
};
