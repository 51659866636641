import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { suspensionForm } from "../helpers/forms";
import { startLoadCadenas, startLoadProductos } from "../slices/catalogo/catalgoThunk";
import { useModal } from "./useModal";
import { getSucursalesData } from "../slices/sucursales/sucursalesThunk";
import { deleteSuspension, getSuspensionesData, insertSuspension, updateSuspension } from "../slices/suspensiones/suspensionesThunk";
import { useExportFile } from "./useExportFile";
import moment from "moment/moment";

export const useSuspensionesTable = () => {
  const [form, setForm] = useState(suspensionForm);
  const { dataTable, dataTableBitacora } = useSelector((state) => state.suspensiones);
  const { dataTable: sucursales } = useSelector((state) => state.sucursales);
  const { cadenas, productos } = useSelector((state) => state.catalogo);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    open: false,
    type: "add"
  })
  const { setOpenToast } = useModal();
  const [isBitacoraOpen, setIsBitacoraOpen] = useState(false)

  const { downloadFile } = useExportFile()

  const [isLoaded, setIsLoaded] = useState({
    cadenas: false,
    productos: false,
    sucursales: false
  })

  useEffect(() => {
    setIsLoaded({
      ...isLoaded,
      sucursales: false
    })
    setIsBitacoraOpen(false)
  }, [showModal])

  const handleBitacoraOpen = async(flag) => {
    if (flag) {
      await dispatch(getSuspensionesData(0,-1,true));
    }
    setIsBitacoraOpen(flag)
  }

  const onClickAdd = async () => {
    await openModal("add", suspensionForm)
  }

  const openModal = async (type, form = {}) => {
    let innerIsLoaded = { ...isLoaded }
    if (!innerIsLoaded.cadenas || !cadenas.rows.length) {
      await dispatch(startLoadCadenas());
      innerIsLoaded.cadenas = true
    }
    let innerProductos = productos
    if (!innerIsLoaded.productos && form.nIdCadena && !form.nTodosProductos) {
      innerProductos = await dispatch(startLoadProductos());
      innerIsLoaded.productos = true
    }
    let innerSucursales = sucursales
    console.log(innerIsLoaded)
    if (!innerIsLoaded.sucursales && form.nIdCadena && !form.nTodasSucursales) {
      innerSucursales = await dispatch(getSucursalesData(0, form.nIdCadena));
      innerIsLoaded.sucursales = true
    }

    setIsLoaded(innerIsLoaded)

    setShowModal({
      open: true,
      type
    })

    let sProductos = form.sProductos instanceof Object ? form.sProductos : JSON.parse(form.sProductos)
    let sSucursales = form.sSucursales instanceof Object ? form.sSucursales : JSON.parse(form.sSucursales)
    let sBitacora = form.sBitacora instanceof Object ? form.sBitacora : JSON.parse(form.sBitacora)

    if (sProductos.length) {
      const ids_productos = sProductos.reduce((arr, item) => {
        arr.push(item.sClaveProducto)
        return arr
      }, [])
      sProductos = [...innerProductos].filter(producto => ids_productos.includes(producto.sClaveProducto))
    }

    if (sSucursales.length) {
      const ids_sucursales = sSucursales.reduce((arr, item) => {
        arr.push(item.nIdSucursal)
        return arr
      }, [])
      sSucursales = [...innerSucursales.rows].filter(sucursal => ids_sucursales.includes(sucursal.nIdSucursal))
    }

    setForm({
      ...suspensionForm,
      ...form,
      sProductos,
      sSucursales,
      sBitacora
    })
  }

  const enableEditModal = () => {
    setShowModal({
      open: true,
      type: "edit"
    })
  }

  const disableEditModal = () => {
    setShowModal({
      open: true,
      type: "view"
    })
  }

  const loadInInit = async () => {
    await dispatch(getSuspensionesData());
  };

  const onChangeInput = async (value) => {
    setForm({ ...form, ...value });
  };

  const onChange = async (e) => {
    console.log(e)
    const {
      name,
      value,
      checked
    } = e?.target ?? {};

    const switchInputsName = ["nTodosProductos", "nTodasSucursales"]

    let innerIsLoaded = { ...isLoaded }
    let innerForm = {
      ...form,
      ...Object.keys(e).includes("target") ?
        { [name]: switchInputsName.includes(name) ? checked : value } :
        e
    }

    switch (name) {
      case "nTodosProductos":
        if (checked) {
          innerForm = { ...innerForm, sProductos: [] }
        } else {
          if (form.nIdCadena && !innerIsLoaded.productos) {
            await dispatch(startLoadProductos());
            innerIsLoaded.productos = true
          }
        }
        break;
      case "nTodasSucursales":
        if (checked) {
          innerForm = { ...innerForm, sSucursales: [] }
        } else {
          if (form.nIdCadena && !innerIsLoaded.sucursales) {
            await dispatch(getSucursalesData(0, form.nIdCadena));
            innerIsLoaded.sucursales = true
          }
        }
        break;
      case "nIdCadena":
        innerIsLoaded.sucursales = false
        innerForm = { ...innerForm, ...suspensionForm, nIdCadena: innerForm.nIdCadena }
        break;
      case "sSucursales":
        if (innerForm.sSucursales.length === sucursales.rows.length) {
          innerForm = { ...innerForm, nTodasSucursales: true, sSucursales: [] }
        }
        break;
      case "sProductos":
        if (innerForm.sProductos.length === productos.length) {
          innerForm = { ...innerForm, nTodosProductos: true, sProductos: [] }
        }
        break;
      default:
        break;
    }


    setForm(innerForm);
    setIsLoaded(innerIsLoaded)

  }

  const handleSubmit = async () => {
    const validatedData = validateData()

    if (validatedData.ok) {

      const innerForm = {
        ...form,
        nTipo: getTipoSuspension()
      }

      const resp = await dispatch(showModal.type === "add" ? insertSuspension(innerForm) : updateSuspension(innerForm))
      setOpenToast(!resp.ok, resp.msg);
      if (resp.ok) {
        handleCloseModal()
        await dispatch(getSuspensionesData());
      }
    } else {
      setOpenToast(true, validatedData.error);
    }
  }

  const handleSubmitChangeEstatus = async (inrForm = false) => {
    const validatedData = validateData(inrForm)

    if (validatedData.ok) {

      const innerForm = {
        ...(inrForm ? inrForm : form),
        nTipo: getTipoSuspension()
      }

      const resp = await dispatch(deleteSuspension(innerForm))
      setOpenToast(!resp.ok, resp.msg);
      if (resp.ok) {
        handleCloseModal()
        await dispatch(getSuspensionesData());
      }
      return resp
    } else {
      setOpenToast(true, validatedData.error);
    }

  }

  const getTipoSuspension = () => {
    const {
      nTodasSucursales,
      nTodosProductos
    } = form
    if (nTodasSucursales && nTodosProductos) return 1
    if (nTodasSucursales && !nTodosProductos) return 2
    if (!nTodasSucursales && nTodosProductos) return 3
    if (!nTodasSucursales && !nTodosProductos) return 4
  }

  const handleCloseModal = () => {
    setShowModal({
      open: false,
      type: showModal.type
    })
  }

  const validateData = (innerForm = null) => {
    innerForm = innerForm ?? form
    if (!innerForm.nIdCadena)
      return { ok: false, error: 'Seleccione un comisionista' }
    if (!innerForm.nTodosProductos && !innerForm.sProductos.length)
      return { ok: false, error: 'Seleccione al menos un producto a suspender' }
    if (!innerForm.nTodasSucursales && !innerForm.sSucursales.length)
      return { ok: false, error: 'Seleccione al menos una sucursal a suspender' }
    if (!innerForm.sComentario)
      return { ok: false, error: 'El campo "Comentarios" es requerido' }



    return { ok: true, error: '' }
  }

  const downloadBitacora = (format) => {
    let {
      rows = [],
      columns = []
    } = dataTableBitacora

    columns = [
      ...columns,
      { field: 'nTodosProductos', headerName: 'Productos suspendidos', type: 'render', flex: 2, renderFunction: (row) => row.nTodosProductos ? "Todos los productos" : `${JSON.parse(row.sProductos).length} producto(s)` },
      { field: 'nTodasSucursales', headerName: 'Sucursales suspendidas', type: 'render', flex: 2, renderFunction: (row) => row.nTodasSucursales ? "Todas las sucursales" : `${JSON.parse(row.sSucursales).length} sucursal(es)` },
      { field: 'sComentario', headerName: 'Comentario', flex: 3 },
    ]
    const dFecha = moment().format("YYYY-MM-DD")
    console.log(columns)
    downloadFile(rows, columns.map(c => ({...c, format: c.type})), format, `Bitácora suspensiones (${dFecha})`, "Bitácora de suspensiones", `Fecha: ${dFecha}`)
  }

  return {
    dataTable,
    loadInInit,
    onClickAdd,
    showModal,
    handleCloseModal,
    handleSubmit,
    form,
    onChangeInput,
    openModal,
    enableEditModal,
    disableEditModal,
    onChange,
    cadenas,
    productos,
    sucursales,
    handleSubmitChangeEstatus,
    handleBitacoraOpen,
    isBitacoraOpen,
    dataTableBitacora,
    downloadBitacora
  };
};
