export const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const regexTelefono = /^[0-9]{10}$/i
export const regexClabeBanc = /^[0-9]{18}$/i
export const regexNums = /^[0-9]+$/i
export const regexRFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
export const regexStrageChart = /[^a-zA-Z0-9\s&-().ÁÉÍÓÚáéíóúÑñ-]/g
export const regexNumChart = /^[A-Za-z0-9\s-]+$/i
export const regexPassword = /^.{8,}$/
export const regexFormatPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.])[A-Za-z\d@$!%*?&#.]{8,20}$/
export const regexLatitud = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
export const regexLongitud = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
export const regexNumExterior = /^[0-9]+$|^(SN)+$|^(S\/N)+$|^(SIN NUMERO)+$|^(SIN NÚMERO)+$/i;
export const regexCodigoPostal = /^\d{4,5}$/i;
export const regexUsuarioSFTP = /^[a-zA-Z0-9_-]+$/