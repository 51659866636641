import { API } from 'aws-amplify';
import { getDatosFacturacion } from '../../helpers/forms';
import { existDataByValue } from '../../helpers/getData';
import { localStorageKeys } from '../../helpers/types';
import { setBancoSearched, setDireccionSearched } from '../cadena/cadenaSlice';
import { loading, tableLoading } from '../ui/uiSlice';
import {
  setBancos,
  setDocumentos,
  setGiros,
  setProcesos,
  setRetenciones,
  setComsiones,
  setIntegraciones,
  setCadenas,
  setTiposLiquidacion,
  setEstados,
  setCiudades,
  setColonias,
  setProductos,
  setCatProductos,
  setEmisores,
  setCfdis,
  setFormasPago,
  setMetodosPago,
  setRegimensFical,
  setUnidades,
  setProductoServicios,
  setEstatusPago,
  setRutas,
  setEscenariosContables,
  setMotivosBaja,
  setIdentificadores,
  setEstatusFacturacion,
  setEstatusConciliacion,
  setPerfiles,
  setOpciones,
  setSecciones,
  setIntegradores,
  setSectoresCNBV,
  setEstatusCNBV,
  setCuentasContables,
  setTareas,
  setArchivosSFTP,
  setCarpetasSFTP,
  setTiposActor
} from './catalagoSlice';

export const startLoadSectoresCNBV = (nIdSector = 0, catalogoPage = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdSectorCNBV: nIdSector }
    }
    let data = []
    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      const resp = await API.get('catalogos', '/catalogo/sectores-cnbv', params);
      data = resp.map((sector) => ({
        ...sector,
        value: sector.nIdSector + '',
        text: sector.sDescripcion
      }));

      dispatch(setSectoresCNBV(data));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      if(!catalogoPage) dispatch(loading(false))
      return data
    }

  }
}

export const startLoadEstatusCNBV = (nIdEstatusCNBV = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdEstatusCNBV }
    }
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/estatus-cnbv', params);
      data = resp.map((estatus) => ({
        ...estatus,
        value: estatus.nIdEstatusCNBV + '',
        text: estatus.sDescripcion
      }));

      dispatch(setEstatusCNBV(data));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return data
    }

  }
}

export const startLoadGiros = (idGiro = 0, catalogoPage = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdGiro: idGiro }
    }
    let data = []
    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      const resp = await API.get('catalogos', '/catalogo/giros', params);
      const giros = resp.map((giro) => ({
        ...giro,
        value: giro.nIdGiro + '',
        text: giro.sDescripcion
      }));

      dispatch(setGiros(giros));
      data = giros

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      if(!catalogoPage) dispatch(loading(false))
      return data
    }

  }
}


export const startLoadProcesos = (idProceso = 0, nTipo = 0, catalogoPage = false) => {
  return async (dispatch, getState) => {
    const { procesosStore = [] } = getState().catalogo;
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdProceso: idProceso, nTipo }
    }
    if (procesosStore.length > 0) return procesosStore;

    let data = procesosStore
    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      const resp = await API.get('catalogos', '/catalogo/procesos', params);
      const procesos = resp.map((proceso) => ({
        ...proceso,
        value: proceso.nIdProceso + '',
        text: proceso.sDescripcion
      }));
      data = procesos
      dispatch(setProcesos(procesos));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(catalogoPage ? tableLoading(false) :loading(false));
    }

    return data
  }
}

export const startLoadTareas = (nIdTarea = 0, nNotificacion = -1, nIdEstatus = -1) => {
  return async (dispatch, getState) => {
    const { procesosStore = [] } = getState().catalogo;
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdTarea, nNotificacion, nIdEstatus }
    }

    if (procesosStore.length > 0) return;
    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/tareas', params);
      

      dispatch(setTareas([...resp]));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}

export const startLoadDocumentos = (idDocumento = 0, nTipo = 0, catalogoPage = false, nIdEstatus = 1) => {
  return async (dispatch, getState) => {
    const { documentos = [] } = getState().catalogo;
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdDocumento: idDocumento, nTipo, nIdEstatus }
    }

    let resp = []
    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      resp = await API.get('catalogos', '/catalogo/documentos', params);
      resp = resp.map((documento) => ({
        ...documento,
        value: documento.nIdDocumento + '',
        text: documento.sNombreDocumento
      }));

      dispatch(setDocumentos(resp));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      if(!catalogoPage) dispatch(loading(false))
    }
    return resp;

  }
}

export const startLoadBancos = (idBanco = '', catalogoPage = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdBanco: parseInt(idBanco.toString())
      }
    }
    let data = []
    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      const resp = await API.get('catalogos', '/catalogo/bancos', params);
      const bancos = resp.map((banco) => ({
        ...banco,
        value: banco.nIdBanco + '',
        text: banco.sNombre
      }));

      dispatch(setBancos(bancos));
      dispatch(setBancoSearched(
        bancos.length > 0 ? `${bancos[0].nIdBanco}` : '',
      ));
      data = bancos
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      if(!catalogoPage) dispatch(loading(false))
      return data
    }

  }
}

export const startLoadRetenciones = (idRetencion = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdRetencion: idRetencion }
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/retenciones', params);
      const retenciones = resp.map((retencion) => ({
        ...retencion,
        value: retencion.nIdRetencion + '',
        text: retencion.sDescripcion
      }));

      dispatch(setRetenciones(retenciones));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}

export const startLoadComsiones = (idPagoComision = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdPagoComision: idPagoComision }
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/pagoComisiones', params);
      const comisiones = resp.map((comision) => ({
        ...comision,
        value: comision.nIdPagoComision + '',
        text: comision.sDescripcion
      }));

      dispatch(setComsiones(comisiones));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}

export const startLoadIntegracion = (idIntegracion = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdIntegracion: idIntegracion }
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/integraciones', params);
      const integraciones = resp.map((integracion) => ({
        ...integracion,
        value: integracion.nIdIntegracion + '',
        text: integracion.sDescripcion
      }));

      dispatch(setIntegraciones(integraciones));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}

export const startLoadFiltrosReportes = (config = {}) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: config
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/filtros', params);
      dispatch(setCadenas({ columns: [], rows: resp.oCadenas }));
      dispatch(setEmisores({ columns: [], rows: resp.oEmisores }));
      dispatch(setProductos(resp.oProductos));
      dispatch(setEstatusPago(resp.oEstatusPago));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}
export const startLoadCadenas = (idCadena = 0, idEstatus = -1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdCadena: idCadena, nIdEstatus: idEstatus }
    }

    try {
      dispatch(tableLoading(true));
      const resp = await API.get('cadenas', '/cadenas/cadenas', params);
      if (!Array.isArray(resp.rows)) throw new Error("Hubo un error al obtener las cadenas. Intente más tarde.")
      dispatch(setCadenas(resp));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(tableLoading(false));
    }

  }
}


export const startLoadTiposLiquidacion = (idTipoLiquidacion = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdTipoLiquidacion: idTipoLiquidacion }
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/tipoLiquidacion', params);
      const tiposLiquidacion = resp.map((liquidacion) => ({
        ...liquidacion,
        value: liquidacion.nIdTipoLiquidacion + '',
        text: liquidacion.sDescripcion
      }));

      dispatch(setTiposLiquidacion(tiposLiquidacion));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}
export const startLoadColonias = (codigoPostal = '', nIdColonia = null) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { sCodigoPostal: codigoPostal }
    }
    let resp = {
      colonia: "",
      estado: "",
      ciudad: ""
    }
    try {
      dispatch(loading(true));
      const response = await API.get('catalogos', '/catalogo/colonias', params);

      const estados = [];
      const ciudades = [];
      const colonias = [];

      response?.forEach((res) => {

        if (!existDataByValue(res.nIdColonia, colonias))
          colonias.push({ value: res.nIdColonia + '', text: res.sNombreColonia });

        if (!existDataByValue(res.sNombreEntidad, estados))
          estados.push({ value: res.sNombreEntidad, text: res.sNombreEntidad });

        if (!existDataByValue(res.sNombreMunicipio, ciudades))
          ciudades.push({ value: res.sNombreMunicipio, text: res.sNombreMunicipio });


      });

      dispatch(setEstados(estados));
      dispatch(setCiudades(ciudades));
      dispatch(setColonias(colonias));

      let selectedColonia = null;
      if (nIdColonia) {
        selectedColonia = response.find(colonia => parseInt(colonia.nIdColonia) === parseInt(nIdColonia))
      }
      resp = {
        colonia: nIdColonia && selectedColonia ? selectedColonia.nIdColonia : colonias.length > 0 ? colonias[0].value : '',
        estado: nIdColonia && selectedColonia ? selectedColonia.sNombreEntidad : estados.length > 0 ? estados[0].value : '',
        ciudad: nIdColonia && selectedColonia ? selectedColonia.sNombreMunicipio : ciudades.length > 0 ? ciudades[0].value : '',
      }
      dispatch(setDireccionSearched(resp));

    } catch (error) {
      console.log('ocurrio un error' + error);
      dispatch(setEstados([]));
      dispatch(setCiudades([]));
      dispatch(setColonias([]));
      dispatch(setDireccionSearched({
        colonia: '',
        estado: '',
        ciudad: '',
      }));

    } finally {
      dispatch(loading(false));
      return (resp)
    }

  }
}


export const getColonias = (codigoPostal = '') => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { sCodigoPostal: codigoPostal }
    }
    let resp = []
    try {
      dispatch(loading(true));
      const response = await API.get('catalogos', '/catalogo/colonias', params);
      console.log(response)
      resp = response

    } catch (error) {
      console.log('ocurrio un error' + error);
    } finally {
      dispatch(loading(false));
    }

    return (resp)
  }
}


export const startLoadProductos = (claveProducto = 0, nIdFlujoImporte = 2) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { sClaveProducto: claveProducto, nIdFlujoImporte }
    }
    let productos = []
    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/productos', params);
      productos = resp?.map((producto) => ({
        ...producto,
        value: producto.sClaveProducto + '',
        text: producto.sDescripcion
      }));

      dispatch(setProductos(productos));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return productos
  }
}

export const startLoadEmisores = (nIdEmisor = 0, nIdEstatus = -1, nIntegradores = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdEmisor, nIdEstatus, nIntegradores}
    }

    try {
      dispatch(tableLoading(true));
      const resp = await API.get('emisores', '/emisores/listado', params);


      dispatch(setEmisores(resp));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(tableLoading(false));
    }

  }
}

export const startLoadIntegradores = (nIdEmisor = 0, nIdEstatus = 1, nIntegradores = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdEmisor, nIdEstatus, nIntegradores}
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('emisores', '/emisores/listado', params);

      const {
        rows = [],
        columns = []
      } = resp

      dispatch(setIntegradores(rows));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}

export const startLoadRutas = (nIdRuta = 0, nIdEmisor = 0, nIdCadena = 0, nIdEstatus = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdRuta,
        nIdEmisor,
        nIdCadena,
        nIdEstatus,
      },
    };
    let resp = {}
    try {
      dispatch(loading(true));
      resp = await API.get("emisores", "/emisores/rutas", params);
      dispatch(
        setRutas({
          ...resp
        })
      );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp
    }
  };
}

export const startLoadDatosFacturacion = ({
  nIdCFDI = 0,
  nIdFormaPago = 0,
  nIdMetodo = 0,
  nIdRegimenFiscal = 0
}) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdCFDI, nIdFormaPago, nIdMetodo, nIdRegimenFiscal }
    };

    let cdfis = [];
    let formasPago = [];
    let metodosPago = [];
    let regimensFiscal = [];
    let productoServicios = [];
    let unidades = [];
    try {
      dispatch(loading(true));
      const datosFacturacion = getDatosFacturacion();


      if (!!!datosFacturacion) {
        const resp = await API.get('catalogos', '/catalogo/datosFacturacion', params);
        const {
          cfdiList = [],
          formasPagoList = [],
          metodosPagoList = [],
          regimensFiscalList = [],
          productoServicio = [],
          unidad = []
        } = resp;
        cdfis = cfdiList.map((cdfi) => ({
          ...cdfi,
          value: cdfi.strUsoCFDI,
          text: cdfi.strDescripcion
        }));

        formasPago = formasPagoList.map((pago) => ({
          ...pago,
          value: pago.strFormaPago,
          text: pago.strDescripcion
        }));

        metodosPago = metodosPagoList.map((metodo) => ({
          ...metodo,
          value: metodo.strMetodoPago,
          text: metodo.strDescripcion
        }));

        regimensFiscal = regimensFiscalList.map((regimen) => ({
          ...regimen,
          value: regimen.strRegimenFiscal,
          text: regimen.strDescripcion
        }));

        productoServicios = productoServicio.map((producto) => ({
          ...producto,
          value: producto.strClaveProducto,
          text: producto.strClaveProducto + '-' + producto.strDescripcion
        }));

        unidades = unidad.map((uni) => ({
          ...uni,
          value: uni.strUnidad,
          text: uni.strUnidad + '-' + uni.strDescripcion
        }));

        localStorage.setItem(
          localStorageKeys.factura,
          JSON.stringify({
            cdfis,
            formasPago,
            metodosPago,
            regimensFiscal,
            productoServicios,
            unidades
          })
        );

      } else {
        cdfis = getDatosFacturacion().cdfis;
        formasPago = getDatosFacturacion().formasPago;
        metodosPago = getDatosFacturacion().metodosPago;
        regimensFiscal = getDatosFacturacion().regimensFiscal;
        productoServicios = getDatosFacturacion().productoServicios;
        unidades = getDatosFacturacion().unidades
      }
      dispatch(setCfdis(cdfis));
      dispatch(setFormasPago(formasPago));
      dispatch(setMetodosPago(metodosPago));
      dispatch(setRegimensFical(regimensFiscal));
      dispatch(setUnidades(unidades));
      dispatch(setProductoServicios(productoServicios));

    } catch (error) {
      console.log('ocurrio un error' + error);
    } finally {
      dispatch(loading(false));
      return {
        cfdis: cdfis,
        formasPago,
        metodosPago,
        regimensFiscal,
        productoServicios,
        unidades
      }
    }
  };
};

export const startLoadEstatusPago = (nIdEstatusPago = 0, nIdEstatus = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdEstatusPago, nIdEstatus }
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/estatusPago', params);
      console.log(resp);
      dispatch(setEstatusPago(resp));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}

export const startLoadEscenariosContables = (nIdEscenario = 0, nIdEstatus = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdEscenario, nIdEstatus }
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/escenariosContables', params);
      console.log(resp);
      dispatch(setEscenariosContables(resp));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}

export const startLoadMotivosBaja = (nIdMotivoBaja = 0, nIdEstatus = 1, catalogoPage = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdMotivoBaja, nIdEstatus }
    }
    let resp = []
    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      resp = await API.get('catalogos', '/catalogo/motivosBaja', params);
      dispatch(setMotivosBaja(resp));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      if(!catalogoPage) dispatch(loading(false))
    }

    return resp;
  }
}

export const startLoadIdentificadorCNBV = ({ nIdSector = '0', sDescripcion = '' }, catalogoPage = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdSector, sDescripcion }
    }

    let result = { ok: false, msg: '' }

    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      const resp = await API.get('catalogos', '/catalogo/identificadorEFR', params);

      const identificadores = resp.map((ident) => ({
        ...ident,
        value: `${ident.nIdIdentificador}`,
        text: `${ident.sIdentificador}`
      }));
      dispatch(setIdentificadores(identificadores));

      result = { ok: true, data: identificadores }

    } catch (error) {
      console.log('ocurrio un error' + error)
      result = { ok: false, data: [] }

    } finally {
      if(!catalogoPage) dispatch(loading(false))
      return result;
    }

  }
}

export const startLoadEstatusFacturacion = (nIdEstatusFacturacion = 0, nIdEstatus = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdEstatusFacturacion, nIdEstatus }
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/estatusFacturacion', params);
      console.log(resp);
      dispatch(setEstatusFacturacion([...resp]));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}

export const startLoadEstatusConciliacion = (nIdEstatusConciliacion = 0, nIdEstatus = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdEstatusConciliacion, nIdEstatus }
    }

    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/estatusConciliacion', params);
      console.log(resp);
      dispatch(setEstatusConciliacion([...resp]));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
}

export const startLoadPermisosData = (nIdPerfil = 0, nIdEstatus = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdPerfil, nIdEstatus }
    }
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get('usuarios', '/usuarios/catalogo-perfil', params);
      const {
        perfiles = [],
        opciones = [],
        secciones = []
      } = resp
      data = perfiles
      dispatch(setPerfiles(perfiles));
      dispatch(setOpciones(opciones));
      dispatch(setSecciones(
        secciones?.sort((a, b) => (a.nOrden < b.nOrden ? -1 : 1))
      ));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return data
  }
}

export const postCatalogoBancos = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/bancos', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const putCatalogoBancos = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/bancos', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const deleteCatalogoBancos = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/bancos', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const postCatalogoGiros = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/giros', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const putCatalogoGiros = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/giros', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const deleteCatalogoGiros = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/giros', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const postCatalogoIdentificadorCNBV = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        ...body,
        sClave: String(body.sClave).padStart(3,"0"),
        sIdentificador: body.sIdentificador.toUpperCase()
      }
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/cnbv-identificadores', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const putCatalogoIdentificadorCNBV = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        ...body,
        sClave: String(body.sClave).padStart(3,"0"),
        sIdentificador: body.sIdentificador.toUpperCase()
      }
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/cnbv-identificadores', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const deleteCatalogoIdentificadorCNBV = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        ...body,
        sClave: String(body.sClave).padStart(3, "0"),
        sIdentificador: body.sIdentificador.toUpperCase()
      }
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/cnbv-identificadores', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const postCatalogoSectoresCNBV = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/cnbv-sectores', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const putCatalogoSectoresCNBV = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/cnbv-sectores', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const deleteCatalogoSectoresCNBV = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/cnbv-sectores', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const startLoadCuentasContables = (nIdCuenta = 0, nIdEstatus = -1, catalogoPage = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdCuenta, nIdEstatus }
    }
    let data = []
    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      const resp = await API.get('catalogos', '/catalogo/cuentas-contables', params);
      const cuentas = [...resp]
      dispatch(setCuentasContables(cuentas));
      data = cuentas

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      if(!catalogoPage) dispatch(loading(false))
      return data
    }

  }
}

export const putCatalogoCuentasContables = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/cuentas-contables', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const deleteCatalogoCuentasContables = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/cuentas-contables', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return response
    }

  }
}

export const postCatalogoDocumentos = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/documentos', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const putCatalogoDocumentos = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/documentos', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const deleteCatalogoDocumentos = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/documentos', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const postCatalogoMotivosBaja = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/motivos-baja', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const putCatalogoMotivosBaja = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/motivos-baja', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const deleteCatalogoMotivosBaja = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/motivos-baja', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}


export const startLoadCfgGeneral = (nProceso = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nProceso }
    }
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/configuracion-general', params);
      data = [...resp]

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false))
    }
    return data

  }
}


export const postCatalogoProcesos = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/procesos', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const putCatalogoProcesos = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/procesos', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const deleteCatalogoProcesos = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/procesos', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const startLoadCarpetasSFTP = (nIdCarpetaSFTP = 0, nIdEstatus = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdCarpetaSFTP, nIdEstatus }
    }

    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/carpetas-sftp', params);
      data = [...resp]
      dispatch(setCarpetasSFTP(data));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

    return data
  }
}

export const startLoadArchivosSFTP = (nIdArchivoSFTP = 0, nIdEstatus = 1, catalogoPage = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdArchivoSFTP, nIdEstatus }
    }

    let data = []
    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      const resp = await API.get('catalogos', '/catalogo/archivos-sftp', params);
      data = [...resp]
      dispatch(setArchivosSFTP(data));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(catalogoPage ? tableLoading(false) :loading(false));
    }

    return data
  }
}

export const postCatalogoArchivoSFTP = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/archivos-sftp', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const putCatalogoArchivoSFTP = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/archivos-sftp', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const deleteCatalogoArchivoSFTP = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/archivos-sftp', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}


export const startLoadTiposActor = (nIdTipoActor = 0, nIdEstatus = 1, catalogoPage = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdTipoActor, nIdEstatus }
    }

    let data = []
    try {
      dispatch(catalogoPage ? tableLoading(true) :loading(true));
      const resp = await API.get('catalogos', '/catalogo/tipo-actor', params);
      data = [...resp]
      dispatch(setTiposActor(data));
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(catalogoPage ? tableLoading(false) :loading(false));
    }

    return data
  }
}

export const postCatalogoTiposActor = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/tipo-actor', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const putCatalogoTiposActor = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('catalogos', '/catalogo/tipo-actor', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const deleteCatalogoTiposActor = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = { ok: false, msg: "Ocurrió un error, intente más tarde." }
    try {
      dispatch(loading(true));
      const resp = await API.del('catalogos', '/catalogo/tipo-actor', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}