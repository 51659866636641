import { API } from "aws-amplify";
import { closeOrdenPago, loading, openToast, tableLoading } from "../ui/uiSlice";
import { setDataTable } from "./sucursalesSlice";

export const getSucursalesData = (nIdSucursal = 0, nIdCadena = 0, nIdEstatus = -1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {nIdCadena, nIdEstatus},
    };

    let sucursales = {}
    try {
      dispatch(tableLoading(true));
      const ruta = `/sucursales${nIdSucursal === 0 ? '' : `/${nIdSucursal}`}`
      sucursales = await API.get("sucursales", ruta, params);
        dispatch(
          setDataTable({
            ...sucursales,
          })
        );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableLoading(false));
    }
    return sucursales
  };
};

export const insertSucursal = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {...form},
    };
    let resp = {ok: false, msg: "Error inesperado. Intente más tarde."}
    try {
      dispatch(loading(true));
      const res = await API.post("sucursales", "/sucursales/alta", params);
      resp = {
        ok: res.nCodigo === 0,
        msg: res.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const updateSucursal = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: form,
    };
    let resp = {ok: false, msg: "Error inesperado. Intente más tarde."}
    try {
      dispatch(loading(true));
      const res = await API.put("sucursales", `/sucursales/${form.nIdSucursal}`, params);
      resp = {
        ok: res.nCodigo === 0,
        msg: res.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const updateSucursalStatus = (form, nIdEstatus = -1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        ...form,
        nIdEstatus
      },
    };
    let resp = {ok: false, msg: "Error inesperado. Intente más tarde."}
    try {
      dispatch(loading(true));
      const res = await API.put("sucursales", `/sucursales/estatus`, params);
      resp = {
        ok: res.nCodigo === 0,
        msg: res.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const insertSucursalBaja = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: form,
    };
    let resp = {ok: false, msg: "Error inesperado. Intente más tarde."}
    try {
      dispatch(loading(true));
      const res = await API.del("sucursales", `/sucursales/${form.nIdSucursal}`, params);
      resp = {
        ok: res.nCodigo === 0,
        msg: res.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const importarSucursales = (nFila = 0, sSucursales = []) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: { nFila, sSucursales },
    };
    let resp = {ok: false, msg: "Error inesperado. Intente más tarde.", sSucursales: []}
    try {
      dispatch(loading(true));
      const res = await API.post("sucursales", "/sucursales/importar", params);
      const {
        nCodigo = 1,
        sMensaje = "",
        sSucursales = []
      } = res

      resp = {
        ok: nCodigo === 0,
        msg: sMensaje,
        sSucursales
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const importarSucursalesBaja = (nFila = 0, sSucursales = []) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: { nFila, sSucursales },
    };
    let resp = {ok: false, msg: "Error inesperado. Intente más tarde.", sSucursales: []}
    try {
      dispatch(loading(true));
      const res = await API.del("sucursales", "/sucursales/importar", params);
      const {
        nCodigo = 1,
        sMensaje = "",
        sSucursales = []
      } = res

      resp = {
        ok: nCodigo === 0,
        msg: sMensaje,
        sSucursales
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};