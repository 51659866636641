import { Button, Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useModal } from '../../hooks/useModal';
import { ModalBase } from './ModalBase';
import { Download, FilterAlt, PictureAsPdf, Summarize } from '@mui/icons-material';
import CustomizedTabs from '../CustomizedTabs';
import { DashboardTable } from '../table/DashboardTable';
import ButtonDropdown from '../ButtonDropdown';
import EnhancedTable from '../table/EnhancedTable';
import { SearchInput } from '../form/SearchInput';
import { useModalArchivoConciliacion } from '../../hooks/useModalArchivoConciliacion';
import { InfoText } from './modalDetail/InfoText';
import moment from 'moment';
import PaginatedTable from '../table/PaginatedTable';

export default function ModalArchivoConciliacion() {
  const { isOpenArchivoConciliacion, closeModalArchivoConciliacion, isTableModalLoading } = useModal();
  const {
    archivo,
    form,
    selectedTab,
    handleSelectedTab,
    exportData,
    operacionesConciliadas,
    operacionesNoConciliadas,
    onChange,
    getData
  } = useModalArchivoConciliacion()
  console.log(archivo)

  const isSameDate = (dFecha1, dFecha2) => {
    const dFec1 = formatDate(dFecha1)
    const dFec2 = formatDate(dFecha2)

    return moment(dFec1).isSame(dFec2, 'date')
  }
  const showScreen = () => {
    switch (selectedTab) {
      case "resumen":
        return <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Datos del archivo</Typography>
          </Grid>
          <InfoText title="Estatus del archivo" text={archivo.sEstatusConciliacion} xs={12} sm={4} />
          <InfoText title="Tipo de carga" text={archivo.sTipoCarga} xs={12} sm={4} />
          <InfoText title="Nombre del archivo" text={archivo.sNombre} xs={12} sm={4} />
          <InfoText title="Total registros archivo" text={archivo.nTotalRegistros} xs={12} sm={4} />
          <InfoText title="Registros cargados" text={archivo.nRegistrosCargados} xs={12} sm={4} />
          <InfoText title="Registros con diferencias" text={archivo.nDiferencias} xs={12} sm={4} />

          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Operaciones</Typography>
          </Grid>
          <Grid item xs={12}>
            <DashboardTable
              rows={[
                {
                  name: "SofiPay",
                  nOperaciones: archivo.nOperacionesSofiPay ?? 0,
                  nMonto: archivo.nImporteTotalSofiPay ?? 0,
                  nOperacionesRezagadas: archivo.nOperacionesRezagadas ?? 0,
                  nMontoRezagadas: archivo.nMontoOperacionesRezagadas ?? 0
                },
                {
                  name: archivo.sNombreComercial,
                  nOperaciones: archivo.nIdEstatusConciliacion !== 1 ? archivo.nOperacionesConciliadas : 0,
                  nMonto: archivo.nIdEstatusConciliacion !== 1 ? archivo.nMontoOperacionesConciliadas : 0,
                  nOperacionesRezagadas: archivo.nIdEstatusConciliacion !== 1 ? archivo.nOperacionesRezagadasConciliadas : 0,
                  nMontoRezagadas: archivo.nIdEstatusConciliacion !== 1 ? archivo.nMontoOperacionesRezagadasConciliadas : 0,
                }
              ]}
              totalType={"substraction"}
              tableName={"conciliacionResumeTable"}
              columns={[
                {
                  headerName: "",
                  field: "name",
                  type: "text",
                },
                {
                  headerName: "Operaciones",
                  field: "nOperaciones",
                  type: "number",
                },
                {
                  headerName: "Monto",
                  field: "nMonto",
                  type: "price",
                },
                {
                  headerName: "Operaciones",
                  field: "nOperacionesRezagadas",
                  type: "number",
                },
                {
                  headerName: "Monto",
                  field: "nMontoRezagadas",
                  type: "price",
                },
              ]}
              parentColumns={[
                {
                  headerName: "",
                  colSpan: 1,
                },
                {
                  headerName: `Operaciones esperadas (${moment(formatDate(archivo.dFechaOperacion)).format("YYYY-MM-DD")})`,
                  colSpan: 2,
                },
                {
                  headerName: "Operaciones pendientes (Dias anteriores)",
                  colSpan: 2,
                },
              ]}
            />
          </Grid>
        </Grid>
      case "conciliadas":
        return <Grid item xs={12}>
          <PaginatedTable
            isModalTable
            table={operacionesConciliadas}
            rowId={"nIdOperacion"}
            disablePathParameters
            getDataFn={getData}
            tableName={"operaciones_conciliadas"}
            loading={isTableModalLoading}
            modalFilters={<Grid container spacing={1}>
              <Grid item xs={5}>
                <SearchInput
                  options={[
                    {
                      id: 0,
                      name: "Todos",
                    },
                    {
                      id: 1,
                      name: "Automática",
                    },
                    {
                      id: 2,
                      name: "Manual",
                    },
                  ]}
                  value={form.nTipoConciliacion}
                  name={"nTipoConciliacion"}
                  label={"Tipo de conciliación"}
                  getOptionLabel={"name"}
                  getIndexLabel={"id"}
                  size='small'
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            }
            disableButtons
          />
        </Grid>
      case "noConciliadas":
        return <Grid item xs={12}>
          <PaginatedTable
            isModalTable
            table={operacionesNoConciliadas}
            rowId={"nIdDifOperacion"}
            getDataFn={getData}
            tableName={"operaciones_no_conciliadas"}
            loading={isTableModalLoading}
            modalFilters={<Grid container spacing={1}>
              <Grid item xs={5}>
                <SearchInput
                  options={[
                    {
                      id: 0,
                      name: "Todos",
                    },
                    {
                      id: 3,
                      name: "Operación no conciliada",
                    },
                    {
                      id: 1,
                      name: "Solicitud de revisión",
                    },
                    {
                      id: 2,
                      name: "Pendiente de autorizar",
                    },
                    {
                      id: 6,
                      name: "Pendiente de rechazar",
                    },
                    {
                      id: 5,
                      name: "Rechazado",
                    },
                  ]}
                  value={form.nEstatusConciliacion}
                  name={"nEstatusConciliacion"}
                  label={"Estatus"}
                  getOptionLabel={"name"}
                  getIndexLabel={"id"}
                  size='small'
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            }
            disablePathParameters
            disableButtons
          />
        </Grid>
    }
  }

  const formatDate = (date) => {
    if (date && date[date.length - 1] === "Z") {
      return date.slice(0, -1);
    } else {
      return date;
    }
  };


  return (
    <ModalBase
      open={isOpenArchivoConciliacion.open}
      style={{ width: selectedTab !== "resumen" ? '1200px' : '800px' }}
      disableLoading
    >
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TitleModal title={`${archivo.sNombreComercial} (${moment(formatDate(archivo.dFechaConciliacion)).format("YYYY-MM-DD")})`} subtitle={"Detalles archivo conciliación"} extraButton={selectedTab !== "resumen" ? <>
          <ButtonDropdown
            startIcon={<Download />}
            label="Exportar"
            options={[
              { startIcon: <PictureAsPdf />, label: "PDF", action: () => exportData("pdf") },
              { startIcon: <Summarize />, label: "Excel", action: () => exportData("excel") },
            ]}
          />
        </> : <></>} />
        <Grid container sx={{ mt: 1, mb: 2, maxHeight: 800, overflow: 'auto', width: "100%" }}>
          <Grid item xs={12} container justifyContent={"space-between"} paddingLeft={1}>
            <Grid item xs={12}>
              <CustomizedTabs
                tabs={[
                  { label: "Resumen", value: "resumen" },
                  { label: "Conciliadas", value: "conciliadas", count: archivo.nOperacionesConciliadas },
                  ...(archivo.nOperacionesNoConciliadas || true ? [{ label: "No conciliadas", value: "noConciliadas", count: archivo.nOperacionesNoConciliadas }] : []),
                ]}
                value={selectedTab}
                onChange={handleSelectedTab}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container padding={2} sx={{ background: "#f9f9f9", borderRadius: 2 }}>
              {showScreen()}
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end',
          }}
        >
          <Button onClick={closeModalArchivoConciliacion} sx={{ color: '#00609C' }}>
            cerrar
          </Button>
        </div>
      </Box>
    </ModalBase>
  );
};


const TitleModal = ({ title, subtitle, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <Summarize fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};
